import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useActionUsers } from 'features/users/api';

import { REGEX, ROLES, ROLE_USER } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { SelectSearch } from 'shared/ui/select-multi';
import { addMessageToast, hasFormValuesChanged } from 'shared/utils';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: TUsers.UserData | null;
  methodsData?: TMethods.MethodItem[];
  onClose: () => void;
  methodID?: number;
  technicalMethodLabel?: string;
  technicalMethodSource?: string;
  setIsOpenAddAccount: React.Dispatch<React.SetStateAction<boolean>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserMethodID?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const AddUserModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  methodsData,
  userData,
  onClose,
  methodID,
  setIsOpenAddAccount,
  setUserID,
  setUserMethodID
}) => {
  const [isPassForgot, setIsPassForgot] = useState(!!userData);
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const [isMethodsDisabled, setIsMethodsDisabled] = useState(false);
  const { user, rolesAccess } = useUser();

  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  useEffect(() => {
    const roleValue = rolesAccess(ROLES.AddUserModal.MethodDisabled, methods.watch('role'));
    setIsCreateAccount(roleValue ?? false);
    setIsMethodsDisabled(!methods.watch('role') && !!rolesAccess(ROLES.AddUserModal.canChooseRole));
  }, [methods.watch()]);

  const onChangeRole = (role: string) => {
    methods.clearErrors('method_id');
    switch (role) {
      case 'Менеджер':
        setIsMulti(true);
        methods.setValue('method_id', []);
        break;
      case 'Администратор':
        setIsMulti(true);
        methods.setValue('method_id', 'All methods');
        break;
      default:
        setIsMulti(false);
        methods.setValue('method_id', null);
        break;
    }
  };

  const managerMethods = () => {
    return methods.getValues('role') === 'Администратор' || hasOneMethod ? (
      <Input
        methods={methods}
        key={isMulti.toString()}
        placeholder='Text'
        label='Method'
        name='method_id'
        rules={{ required: false }}
        autofill
        defaultValue={hasOneMethod ? user?.methods?.[0]?.name : undefined}
      />
    ) : (
      <SelectSearch
        isMulti={isMulti}
        methods={methods}
        key={isMulti.toString()}
        inputPlaceholder='Text'
        name='method_id'
        disabled={isMethodsDisabled}
        inputLabel='Method*'
        rules={{
          required: 'Please, choose a method'
        }}
        options={methodsOptions}
      />
    );
  };

  const methodsRes = (userData: TUsers.UserData) => {
    switch (userData.role) {
      case 'Агент':
        return userData?.methods?.[0]?.id;
        break;
      case 'Администратор':
        return 'All methods';
        break;
      default:
        return userData.methods.map(i => i.id);
        break;
    }
  };

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), userData ?? {}, ['method_id']);

  const hasOneMethod =
    rolesAccess(ROLES.AddUserModal.hasOneMethod, user?.role) && user?.methods?.length === 1;

  const methodData = methodsData?.find(method => method.id === methodID);

  useEffect(() => {
    setIsPassForgot(!!userData);
    if (userData && isOpen) {
      const { methods, ...dataRes } = userData;
      if (rolesAccess(ROLES.AddUserModal.methodsSelectIsMulti, userData.role)) {
        setIsMulti(true);
      }
      const methodsSelect = methodsRes(userData);
      reset({ ...dataRes, method_id: methodsSelect });
    } else {
      reset({});
    }

    if (!isOpen) {
      setIsMulti(false);
    }
  }, [userData, isOpen]);

  const { create, update } = useActionUsers();
  const methodRes = (data: TUsers.CreateUser) => {
    if (hasOneMethod) return user?.methods[0]?.id.toString();
    if (data.method_id === 'All methods') return undefined;
    if (methodID) return methodID.toString();
    if (Array.isArray(data.method_id)) return data.method_id.join(',');
    return data.method_id;
  };

  const onSubmit: SubmitHandler<TUsers.CreateUser> = async data => {
    const userRole = rolesAccess(ROLES.AddUserModal.canChooseRole) ? data.role : 'Агент';
    try {
      if (!userData) {
        const { data: user } = await create.mutateAsync({
          ...data,
          role: userRole,
          method_id: methodRes(data)
        });
        addMessageToast('User created', 'success');
        if (rolesAccess(ROLES.AddUserModal.createAcc, user.role)) {
          setUserID(user.id);
          setUserMethodID?.(parseInt(data.method_id!));
        } else {
          reset();
        }
      } else {
        await update.mutateAsync({
          ...data,
          role: userRole,
          user_id: userData.id,
          method_id: methodRes(data)
        });
        addMessageToast('User updated', 'success');
      }
      onClose();
      reset([]);
    } catch (error) {
      addMessageToast(error);
    }
  };

  const methodsOptions = useMemo(() => {
    return methodsData?.map(item => ({ label: item.name, value: item.id })) || [];
  }, [methodsData]);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        onClose();
      }}
      isOpen={isOpen}
      titleText={userData ? 'Edit user' : 'Add user'}
      reset={() => {
        reset();
        methods.clearErrors();
      }}
      maxWidth={413}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid
            container
            direction={'column'}
            columnGap={'20px'}
            rowGap={15}
            wrap={'nowrap'}
            gridTemplateColumns={'1fr'}
            display='grid'
            gridTemplateRows='auto auto 1fr'>
            <RoleAccessWrapper
              accessCondition={ROLES.AddUserModal.canChooseRole}
              currentRole={user?.role}>
              <Grid item>
                <SelectSearch
                  methods={methods}
                  name='role'
                  inputPlaceholder='Text'
                  inputLabel='Role*'
                  rules={{
                    required: 'Please, choose a role'
                  }}
                  onChangeSelect={e => {
                    if (!Array.isArray(e)) {
                      onChangeRole(e);
                    }
                  }}
                  options={ROLE_USER}
                />
              </Grid>
            </RoleAccessWrapper>
            {methodData === undefined && <Grid item>{managerMethods()}</Grid>}
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Email*'
                name='email'
                rules={{
                  required: 'Please, enter email',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Enter the correct email'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Name*'
                name='name'
                rules={{ required: 'Please, enter name' }}
              />
            </Grid>
            <Grid
              key={isPassForgot.toString()}
              item
              sx={{
                order: userData ? '100' : '0',
                marginTop: isPassForgot ? '-8px' : '0',
                position: 'relative'
              }}>
              {isPassForgot ? (
                <Button
                  variant='text'
                  onClick={() => setIsPassForgot(false)}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    fontSize: '16px',
                    fontWeight: '500',
                    padding: 0
                  }}>
                  Forgot password?
                </Button>
              ) : (
                <Input
                  methods={methods}
                  placeholder='Text'
                  label={userData ? 'New password*' : 'Password*'}
                  name='password'
                  isPass
                  rules={{
                    required: 'Please, enter password',
                    minLength: { message: 'Minimum length - 10', value: 10 }
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'30px'}
          display={'flex'}
          gap={'16px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              maxWidth: isCreateAccount ? 175 : 'none',
              height: 40,
              boxShadow: 'none'
            }}>
            Save
          </Button>
          {isCreateAccount && !userData && (
            <Button
              type='button'
              onClick={async () => {
                await onSubmit(methods.getValues() as TUsers.CreateUser);
                setIsOpenAddAccount(true);
              }}
              fullWidth
              variant='contained'
              sx={{
                m: 0,
                borderRadius: 50,
                textTransform: 'none',
                maxWidth: 175,
                height: 40,
                boxShadow: 'none'
              }}>
              Create account
            </Button>
          )}
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
