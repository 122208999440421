import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { AppsService } from 'shared/services/apps.service';
import { TPagination } from 'shared/services/types/common.types';

export const useGetApps = (data: TPagination) => {
  const {
    isLoading: isAppsLoading,
    data: appsData,
    isError: isAppsError,
    isFetching: isAppsFetching
  } = useQuery({
    queryFn: () => AppsService.getApps(data),
    queryKey: ['get apps', data],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return { isAppsLoading, appsData, isAppsError, isAppsFetching };
};
