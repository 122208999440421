import { FC, useMemo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { useGetApplications } from 'features/applications';
import { useGetTransactions } from 'features/transactions';

import { CompareIcon, MailIcon } from 'shared/assets/icons';
import { ROLES } from 'shared/constants';
import { useGetDate, useUser } from 'shared/hooks';
import { useUrlParams } from 'shared/hooks/useUrlParams';
import { TApplications } from 'shared/services/types/application.types';
import { DateRange, TFilters, TStatusType } from 'shared/services/types/common.types';
import { TTransaction } from 'shared/services/types/transaction.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { MethodTableLayout } from 'shared/ui/method-table-layout';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { Status } from 'shared/ui/status';
import { TableContentWrapper } from 'shared/ui/table-content-wrapper';
import { CustomTooltip } from 'shared/ui/tooltip';
import { getTransformedDate } from 'shared/utils';

import { APPLICATIONS } from '../default-columns/APPLICATIONS';
import { TRANSACTIONS } from '../default-columns/TRANSACTIONS';

interface IProps {
  matchTransaction: (id: number) => void;
  pageSize: number;
  status?: TStatusType;
  accountNumber?: string;
  subagentNumber?: string;
  dateFilter: DateRange;
  methodID: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  onSubmitFilters: (data: TFilters) => void;
  openMessagesModal: (message: string, time?: string) => void;
}

export const DefaultTables: FC<IProps> = ({
  matchTransaction,
  pageSize,
  status,
  accountNumber,
  subagentNumber,
  dateFilter,
  methodID,
  setPageSize,
  onSubmitFilters,
  openMessagesModal
}) => {
  const { getDate } = useGetDate();
  const { user } = useUser();

  const { paramsObj } = useUrlParams();
  const page = parseInt(paramsObj.page) - 1;

  const filterT = useMemo(() => {
    return {
      size: pageSize,
      page: page,
      order_by: 'id',
      method_id: methodID,
      account_id: accountNumber,
      subagent_id: subagentNumber,
      status: status ? status : undefined,
      is_desc_sort: true,
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter)
    } as TTransaction.GetTransaction;
  }, [onSubmitFilters, page, pageSize]);

  const filterA = useMemo(() => {
    return {
      size: pageSize,
      page: page,
      order_by: 'id',
      method_id: methodID,
      account_id: accountNumber,
      subagent_id: subagentNumber,
      status: status ? status : undefined,
      is_desc_sort: true,
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter)
    } as TApplications.GetApplication;
  }, [onSubmitFilters, page, pageSize]);

  const { isTransactionsLoading, transactions } = useGetTransactions(filterT);

  const { isApplicationsLoading, applications } = useGetApplications(filterA);

  const total = useMemo(() => {
    if (applications && transactions)
      return applications.total > transactions.total ? applications.total : transactions.total;
  }, [applications, transactions]);

  return (
    <MethodTableLayout pageSize={pageSize} setPageSize={setPageSize} total={total || 0}>
      <TableContentWrapper
        columns={APPLICATIONS}
        sx={{
          '.MuiTableHead-root': {
            '.MuiTableRow-root': {
              '.MuiTableCell-root': {
                '> svg': {
                  marginLeft: '16px !important'
                }
              }
            }
          }
        }}>
        {isApplicationsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          <>
            {applications?.items.map((row, id) => {
              const accountId = methodID === 1 ? row.subagent_id : row.account_id;
              const status = row?.status as TStatusType;
              const isStatusMatch = ['received', 'scam'].includes(row.status);

              return (
                <TableRow
                  key={id}
                  sx={{
                    ':hover': {
                      background: '#185AC21F'
                    }
                  }}>
                  <TableCell align='left'>
                    <Status status={status} />
                  </TableCell>
                  <TableCell align='left'>{row.id}</TableCell>
                  <TableCell align='left'>{getTransformedDate(row?.timestamp)}</TableCell>
                  <TableCell align='left'>{row.transaction_id}</TableCell>
                  <TableCell align='left'>{accountId}</TableCell>
                  <TableCell align='left'>{row.subagent_id}</TableCell>
                  <TableCell align='left'>{row.remarks}</TableCell>
                  <TableCell align='left'>{row.amount}</TableCell>
                  <TableCell align='right' sx={{ cursor: 'pointer', color: '#AEAAAB' }}>
                    <RoleAccessWrapper
                      accessCondition={ROLES.Transactions.canMatch}
                      currentRole={user?.role}>
                      {isStatusMatch && (
                        <CustomTooltip title='Match'>
                          <CompareIcon
                            onClick={() => {
                              matchTransaction(row.id);
                            }}
                          />
                        </CustomTooltip>
                      )}
                    </RoleAccessWrapper>
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        )}
      </TableContentWrapper>
      <TableContentWrapper
        columns={TRANSACTIONS}
        sx={{
          '.MuiTableHead-root': {
            '.MuiTableRow-root': {
              '.MuiTableCell-root': {
                '> svg': {
                  marginLeft: '16px !important'
                }
              }
            }
          }
        }}>
        {isTransactionsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          <>
            {transactions?.items.map((row, id) => {
              const accountId = methodID === 1 ? row.subagent_id : row.account_id;
              const status = row?.status as TStatusType;

              return (
                <TableRow
                  key={id}
                  sx={{
                    ':hover': {
                      background: '#185AC21F'
                    }
                  }}>
                  <TableCell align='left'>
                    <Status status={status} />
                  </TableCell>
                  <TableCell align='left'>{row.id}</TableCell>
                  <TableCell align='left'>{getTransformedDate(row?.timestamp)}</TableCell>
                  <TableCell align='left'>{row.application_id}</TableCell>
                  <TableCell align='left'>{accountId}</TableCell>
                  <TableCell align='left'>{row.subagent_id}</TableCell>
                  <TableCell align='left'>{row.remarks}</TableCell>
                  <TableCell align='left'>{row.income}</TableCell>
                  <TableCell align='right' sx={{ cursor: 'pointer' }}>
                    {row?.push_message && (
                      <CustomTooltip title='Messages'>
                        <MailIcon
                          onClick={() => {
                            openMessagesModal(row?.push_message || '', row?.timestamp);
                          }}
                        />
                      </CustomTooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        )}
      </TableContentWrapper>
    </MethodTableLayout>
  );
};
