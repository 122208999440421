import { FC, useRef, useState } from 'react';

import { PersonOutlineOutlined, Settings } from '@mui/icons-material';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { Avatar, Box, Grid, List, ListItemButton, ListItemIcon } from '@mui/material';
import { useAuthStore } from 'app/store';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

import {
  LogOut,
  LogoIcon,
  MethodsIcon,
  SidenavAppsPage,
  UserAccountIcon
} from 'shared/assets/icons';
import { ROLES } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { AuthService } from 'shared/services/auth/auth.service';
import { TMethods } from 'shared/services/types/methods.types';
import { CustomTooltip } from 'shared/ui/tooltip';

import SettingsPopover from './SettingsPopover';

interface IProps {
  methods?: TMethods.MethodItem[];
}

export const SideNav: FC<IProps> = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const spanRef = useRef(null);

  const open = Boolean(anchorEl);
  const handleOpen = () => {
    setAnchorEl(spanRef.current ?? null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const { pathname } = location;

  const { createdAt, rolesAccess, name, user } = useUser();

  const { customNavigate } = useCustomNavigate();

  const { logout } = useAuthStore();

  const navbarList = {
    top: [
      {
        name: 'Methods',
        pathname: '/',
        icon: (color: string) => <MethodsIcon style={{ color }} />,
        onClick: () => customNavigate('/'),
        protected: false
      }
    ],
    bottom: [
      {
        name: (
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            sx={{ span: { lineHeight: '20px' } }}>
            <span>{name}</span>
            <span>{user?.role}</span>
            <span>{format(createdAt ? createdAt : new Date(0), 'dd.MM.yyyy')}</span>
          </Box>
        ),
        pathname: '/Avatar',
        icon: () => {
          return (
            <Avatar
              alt={`${name}`}
              src='../../shared/assets/icons/profile.png'
              sx={{ width: 24, height: 24 }}
            />
          );
        },
        onClick: () => {},
        protected: false
      },
      {
        name: 'Users',
        icon: (color: string) => <PersonOutlineOutlined style={{ color }} />,
        pathname: '/users/',
        onClick: () => customNavigate('/users/'),
        protected: !rolesAccess(ROLES.User.canRead)
      },
      {
        name: 'Accounts',
        icon: (color: string) => <UserAccountIcon style={{ color }} />,
        pathname: '/user-accounts/',
        onClick: () => customNavigate(`/user-accounts/${user?.id}/${user?.methods?.[0].id}`),
        protected: !rolesAccess(['Агент'])
      },
      {
        name: 'Banks',
        icon: (color: string) => <AccountBalanceOutlinedIcon style={{ color }} />,
        pathname: '/banks',
        onClick: () => customNavigate('/banks'),
        protected: !rolesAccess(ROLES.Banks.canRead)
      },
      {
        name: 'Apps',
        icon: (color: string) => <SidenavAppsPage style={{ color }} />,
        pathname: '/apps',
        onClick: () => customNavigate('/apps'),
        protected: !rolesAccess(ROLES.APKs.canRead)
      },
      {
        name: 'Settings',
        icon: (color: string) => <Settings style={{ color }} />,
        onClick: handleOpen,
        popover: <SettingsPopover open={open} anchorEl={anchorEl} handleClose={handleClose} />
      },
      {
        name: 'Log out',
        icon: (color: string) => <LogOut style={{ color }} />,
        pathname: '/Log out',
        onClick: () => {
          logout();
          AuthService.logout();
        }
      }
    ]
  };

  return (
    <>
      <Box
        position={'fixed'}
        height={'calc(100vh - 24px)'}
        gridColumn={1}
        gridRow='1 / 4'
        sx={{
          bgcolor: 'primary.main',
          color: '#fff',
          borderRadius: 4,
          marginRight: 2,
          overflow: 'auto',
          zIndex: open ? '1301' : '1000',
          '& .MuiDivider-root': { borderColor: '#A4B1EA' },
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'stretch'
        }}>
        <Grid
          container
          sx={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}>
          <Grid
            item
            sx={{
              maxWidth: 1
            }}>
            <Box sx={{ p: 12 }}>
              <LogoIcon />
            </Box>
          </Grid>
          <Grid item>
            <List component='div'>
              {navbarList.top.map(item => {
                const selected = pathname === item.pathname && !open ? '#fff' : 'currentColor';
                if (item.protected) return null;
                return (
                  <CustomTooltip key={item.name} title={item.name}>
                    <ListItemButton itemType='Button' sx={{ p: '12px' }} onClick={item.onClick}>
                      <ListItemIcon>{item.icon(selected)}</ListItemIcon>
                    </ListItemButton>
                  </CustomTooltip>
                );
              })}
            </List>
          </Grid>
        </Grid>
        <Grid item>
          <List component='div' sx={{ padding: 0, paddingBottom: 10 }}>
            {navbarList.bottom.map(item => {
              const selected = pathname === item.pathname && !open ? '#fff' : 'currentColor';
              const settingsOpen = !item.pathname ? '#fff' : 'currentColor';
              if (item.protected) return null;
              return (
                <>
                  <CustomTooltip title={item.name} key={item.pathname} placement='top'>
                    <ListItemButton
                      onClick={item?.onClick}
                      sx={[
                        item.name === 'Settings' &&
                          open && {
                            background: 'rgba(0, 0, 0, 0.04)'
                          }
                      ]}
                      ref={item.name === 'Settings' ? spanRef : undefined}>
                      <ListItemIcon>{item.icon(open ? settingsOpen : selected)}</ListItemIcon>
                    </ListItemButton>
                  </CustomTooltip>
                  {item?.popover}
                </>
              );
            })}
          </List>
        </Grid>
      </Box>
    </>
  );
};
