import { format, startOfYear } from 'date-fns';

/**
 * Получает название месяца по его номеру.
 * @param monthNumber - Номер месяца (1 - январь, 12 - декабрь).
 * @returns Название месяца в формате "MMM" (например, "Jul").
 */
export const getMonthName = (monthNumber: number): string => {
  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error('Номер месяца должен быть от 1 до 12');
  }

  // Создаем дату, устанавливая месяц (месяцы в JavaScript начинаются с 0)
  const date = startOfYear(new Date());
  date.setMonth(monthNumber - 1); // Устанавливаем месяц

  // Форматируем дату для получения названия месяца
  return format(date, 'MMM');
};

// Пример использования
// console.log(getMonthName(7)); // "Jul"
