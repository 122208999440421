import { useQuery } from '@tanstack/react-query';

import { TAccounts } from 'shared/services/types/accounts.types';
import { UsersService } from 'shared/services/users.service';

export const useGetAccounts = (data: TAccounts.GetAccount) => {
  const {
    isLoading: isAccountsLoading,
    data: accounts,
    isError: isAaccountsError,
    ...restData
  } = useQuery({
    queryFn: () => UsersService.getAccounts(data),
    queryKey: ['get account', data],
    retry: 0,
    enabled: !!data.method_id,
    select: ({ data }) => data
  });

  return { isAccountsLoading, accounts, isAaccountsError, ...restData };
};
