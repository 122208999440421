import React, { FC, useEffect, useState } from 'react';

import { Edit } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps, TableCell, TableRow, Theme } from '@mui/material';

import { ApkDownloadIcon, OpenFullIcon, TrashIcon } from 'shared/assets/icons';
import { ROLES } from 'shared/constants';
import { useContainerHeight, useUser } from 'shared/hooks';
import { useUrlParams } from 'shared/hooks/useUrlParams';
import { TApps } from 'shared/services/types/apps.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { TableLayout } from 'shared/ui/table-layout';
import { getTransformedDate } from 'shared/utils';

import { COLUMNS_APPS } from '../mock-data/COL_APPS';

interface IProps {
  appsData?: TApps.GetAppsSuccess;
  isAppsFetching?: boolean;
  isAppsLoading?: boolean;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      page: number;
      size: number;
    }>
  >;
  onActionsApk: (data: Partial<TApps.AppData> | null, action: string) => null | undefined;
  onDownload: (data: TApps.DownloadApp) => Promise<void>;
}
export const TableApps: FC<IProps> = ({
  appsData,
  isAppsFetching,
  isAppsLoading,
  setFilter,
  onActionsApk,
  onDownload
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);
  const { user } = useUser();

  const { paramsObj } = useUrlParams();
  const page = parseInt(paramsObj.page) - 1;

  useEffect(() => {
    setFilter({ size, page });
  }, [size, page]);

  const customTableStyles = {
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: '24px'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        height: '20px',
        padding: '16px 0',
        borderBottom: '1px solid #BDC1D2',
        color: '#44464F',
        fontWeight: '400',
        '&:first-child': {
          paddingLeft: '24px'
        }
      }
    }
  } as SxProps<Theme>;

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip'
      }}>
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden'
        }}>
        <Box
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',
            height: height
          }}
          ref={ref}>
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              ...customTableStyles
            }}>
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              rows={appsData?.items || []}
              columns={COLUMNS_APPS}
              total={appsData?.total || 0}>
              {isAppsLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {appsData?.items.map(row => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          '.MuiButtonBase-root': {
                            p: '0',
                            '&:hover': { backgroundColor: 'inherit !important' }
                          }
                        }}>
                        <TableCell component='th' scope='row'>
                          {row.version}
                        </TableCell>
                        <TableCell align='left'>
                          {getTransformedDate(row?.created_at, 'dd/MM/yy')}
                        </TableCell>
                        <TableCell align='left'>{row.user_id}</TableCell>
                        <TableCell align='left'>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={'10px'}
                            flexWrap={'nowrap'}>
                            <Box
                              sx={{
                                flexGrow: 1,
                                maxWidth: '700px !important',
                                textOverflow: 'ellipsis',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                              }}>
                              {row.description}
                            </Box>
                            <IconButton
                              aria-label='description'
                              size='small'
                              onClick={() => {
                                onActionsApk(row, 'description');
                              }}
                              sx={{ float: 'right', mr: '20px' }}>
                              <OpenFullIcon fontSize='inherit' />
                            </IconButton>
                          </Stack>
                        </TableCell>
                        <TableCell
                          align='right'
                          sx={{
                            paddingRight: '20px'
                          }}>
                          <Stack direction={'row'} gap={'20px'}>
                            <IconButton
                              aria-label='download'
                              size='medium'
                              onClick={() => {
                                onDownload({ version: row.version });
                              }}>
                              <ApkDownloadIcon fontSize='inherit' />
                            </IconButton>
                            <RoleAccessWrapper
                              accessCondition={ROLES.APKs.canEdit}
                              currentRole={user?.role}>
                              <IconButton
                                aria-label='edit'
                                size='medium'
                                onClick={() => {
                                  onActionsApk(row, 'edit');
                                }}>
                                <Edit fontSize='inherit' />
                              </IconButton>
                            </RoleAccessWrapper>
                            <RoleAccessWrapper
                              accessCondition={ROLES.APKs.canDelete}
                              currentRole={user?.role}>
                              <IconButton
                                aria-label='delete'
                                size='medium'
                                sx={{ color: 'error.main' }}
                                onClick={() => {
                                  onActionsApk(row, 'delete');
                                }}>
                                <TrashIcon fontSize='inherit' />
                              </IconButton>
                            </RoleAccessWrapper>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
