import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';

import { AddMethodModal } from 'features/add-method';
import { MethodsItem } from 'features/methods-item';

import { ROLES } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { useUrlParams } from 'shared/hooks/useUrlParams';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { Layout } from 'shared/ui/layout';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';

import { useGetMethods } from '../api';

export const Methods = () => {
  const { isMethodsLoading, sortMethods: methodsList } = useGetMethods();
  const [methodData, setMethodData] = useState<TMethods.MethodItem | undefined>(undefined);
  const { resetParam } = useUrlParams();

  const { customNavigate } = useCustomNavigate();
  const { user, rolesAccess } = useUser();

  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);

  useEffect(() => {
    resetParam('page');
    if (user && !rolesAccess(ROLES.Methods.canRead)) {
      customNavigate('/method/' + user?.method_id);
    }
  }, []);

  const handleOpenMethodModal = (id: number) => {
    setMethodData(methodsList?.find(method => method.id === id));
    setIsOpenAddMethod(true);
  };

  const onCloseMethodModal = () => {
    setIsOpenAddMethod(false);
    setMethodData(undefined);
  };

  return (
    <Layout>
      <Box
        gridColumn='2'
        gridRow='1'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 10,
          py: 7,
          px: 12,
          borderRadius: 4,
          bgcolor: '#fff',
          '.MuiButton-root': {
            padding: ' 10px 28px 10px 24px',
            minWidth: 'fit-content',
            maxHeight: 44,
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: '0.25px',
            '&.MuiButton-contained': {
              fontSize: '14px',
              fontWeight: '400'
            }
          }
        }}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '14px'
          }}>
          <Typography
            variant='h3'
            color='primary'
            sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '500' }}>
            Methods
          </Typography>
        </Box>
        <RoleAccessWrapper accessCondition={ROLES.Method.canCreate} currentRole={user?.role}>
          <Button
            variant='contained'
            startIcon={<Add />}
            onClick={() => {
              setIsOpenAddMethod(true);
            }}>
            Add method
          </Button>
        </RoleAccessWrapper>
      </Box>
      <Box
        gridRow={'2'}
        gridColumn={'2'}
        sx={{
          padding: '14px 24px',
          background: '#fff',
          borderRadius: '16px',
          overflowX: 'scroll'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 'none',
            height: '42px',
            gap: '16px'
          }}>
          {!methodsList ? (
            <Box
              sx={{
                minWidth: 1,
                minHeight: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {(methodsList || []).map((method: TMethods.MethodItem, id: number) => {
                if (!method.is_active) return null;
                return (
                  <ListItemButton
                    onClick={() => {
                      customNavigate(`/method/${method.id}`);
                    }}
                    sx={{
                      borderRadius: '100px',
                      p: '10px 32px',
                      m: 0,
                      minWidth: 'auto',
                      position: 'relative',
                      border: '1px solid rgba(24, 90, 194, 0.5)',
                      color: '#185AC2',
                      flexGrow: '0'
                    }}
                    key={id}>
                    <ListItemText
                      sx={{
                        m: 0,
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        letterSpacing: '0.25px',
                        '.MuiTypography-root': {
                          fontSize: '16px',
                          lineHeight: '20px',
                          width: '100%',
                          minWidth: '100%',
                          display: 'block'
                        }
                      }}
                      primary={method.name}
                    />
                  </ListItemButton>
                );
              })}
            </>
          )}
        </Box>
      </Box>
      <Box
        gridRow={'3/4'}
        gridColumn={'2'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          borderRadius: '16px',
          overflow: 'scroll',
          maxHeight: 'calc(100% - 24px)'
        }}>
        {isMethodsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          (methodsList || []).map((method, i) => (
            <MethodsItem methodData={method} key={method.id} onOpen={handleOpenMethodModal} />
          ))
        )}
      </Box>
      <AddMethodModal
        isOpen={isOpenAddMethod}
        onClose={onCloseMethodModal}
        methodData={methodData}
      />
    </Layout>
  );
};
