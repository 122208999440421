import { FC } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';

import { AuthService } from 'shared/services/auth/auth.service';
import { TAuth } from 'shared/services/types/auth.types';

interface IProps {
  isQRLoading: boolean;
  getQR?: TAuth.TwoFactorCreate;
  isEnabled2fa: boolean;
}

export const QrScan: FC<IProps> = ({ isQRLoading, getQR, isEnabled2fa }) => {
  const searchParams = new URLSearchParams(getQR?.qr.split('?')[1]);
  const TWO_FACTOR_KEY = searchParams.get('secret');

  return (
    <>
      <Typography sx={{ width: '100%', textAlign: 'center', margin: '16px auto 0' }} variant='h5'>
        Use Authentication app to scan this QR code
      </Typography>
      {getQR?.qr && !isEnabled2fa ? (
        <>
          <QRCodeCanvas style={{ marginTop: '26px' }} value={getQR?.qr ?? ''} size={250} />

          <Typography
            sx={{
              display: 'block',
              textAlign: 'center',
              marginTop: '26px',
              color: '#949497',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '20px',
              letterSpacing: '0.25px'
            }}
            variant='body1'>
            Or enter this code in application for authentication
          </Typography>
        </>
      ) : (
        <>
          <Typography
            sx={{
              display: 'block',
              textAlign: 'center',
              marginTop: '26px',
              color: '#949497',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '20px',
              letterSpacing: '0.25px'
            }}
            variant='body1'>
            Authentication not available. Contact Reddy chat to generate a new QR code
          </Typography>
          <Button
            variant='contained'
            type='button'
            onClick={() => {
              AuthService.logout();
            }}
            fullWidth
            sx={{
              marginTop: '26px',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: '500',
              letterSpacing: '0.035px',
              py: '10px'
            }}>
            Log out
          </Button>
        </>
      )}
      {!isEnabled2fa && (
        <Box
          sx={{
            height: '24px',
            display: 'block',
            position: 'relative',
            marginTop: '18px'
          }}>
          <Typography
            style={{
              position: 'absolute',
              top: '0%',
              left: '50%',
              transform: 'translate(-50%, 0)',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
            variant='h5'>
            {TWO_FACTOR_KEY}
          </Typography>
        </Box>
      )}
    </>
  );
};
