import { TRoles } from 'shared/services/types/common.types';

const Admin = 'Администратор' as TRoles;
const Manager = 'Менеджер' as TRoles;
const Agent = 'Агент' as TRoles;
const Application = 'Приложение' as TRoles;

const AdminWithManager = [Admin, Manager];
const AdminWithAgent = [Admin, Agent];
const ManagerAndAgent = [Manager, Agent];
const AgentAndApplication = [Agent, Application];
const AdminWithManagerWithAgent = [Admin, Manager, Agent];

const Method = {
  canCreate: Admin,
  canEdit: Admin,
  canSwitch: AdminWithManager,
  canRead: AdminWithManagerWithAgent,
  canExportData: AdminWithManager
} as const;

const Methods = {
  canRead: AdminWithManager
} as const;

const User = {
  canRead: AdminWithManager,
  canCreate: AdminWithManager,
  canEdit: AdminWithManager,
  editOnlyAgent: Manager,
  managerCanEdit: Agent,
  canSwitch: AdminWithManager,
  hasAccount: Agent
} as const;

const Account = {
  canRead: AdminWithManagerWithAgent,
  create: AdminWithManager,
  edit: AdminWithManager,
  canSwitch: AdminWithManager
} as const;

const Message = {
  canCreate: AdminWithManagerWithAgent
} as const;

const Applications = {
  canCreate: Admin,
  canRead: AdminWithManagerWithAgent,
  checkStatus: AdminWithManagerWithAgent,
  canChange: Admin
} as const;

const Transactions = {
  canCreate: Admin,
  canMatch: AdminWithManagerWithAgent
} as const;

const Withdrawals = {
  canCreate: Manager,
  canEdit: Manager,
  canRead: AdminWithManager
} as const;

const Widgets = {
  canRead: AdminWithManager
} as const;

const APKs = {
  canCreate: Admin,
  canEdit: Admin,
  canRead: AdminWithManagerWithAgent,
  canDelete: Admin
} as const;

const Banks = {
  canCreate: AdminWithManagerWithAgent,
  canUpdate: AdminWithManager,
  canRead: AdminWithManagerWithAgent
} as const;

const Settings = {
  update: Admin
} as const;

const AddUserModal = {
  createAcc: Agent,
  MethodDisabled: Agent,
  hasOneMethod: Manager,
  methodsSelectIsMulti: Manager,
  canChooseRole: Admin
} as const;

export const ROLES = {
  Method,
  Methods,
  User,
  Account,
  Message,
  Applications,
  Transactions,
  Withdrawals,
  Widgets,
  APKs,
  Banks,
  Settings,
  AddUserModal
};
