import { formatDate, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

/**
 * Форматирует строку даты в заданном формате.
 *
 * @param {string} dateTimeString - Строка даты в формате ISO, которую нужно отформатировать.
 * @param {'E' | 'd' | 'yyy-MM-dd' | 'dd/MM/yyyy HH:mm' | 'dd.MM.yyyy HH:mm:ss'} format - Формат, в котором нужно вернуть дату.
 * @returns {string | null} - Отформатированная строка даты или null, если входная строка не задана.
 */

export type TFormatDate =
  | 'E'
  | 'd'
  | 'yyy-MM-dd'
  | 'MM.dd'
  | 'dd.MM'
  | 'dd/MM/yyyy HH:mm'
  | 'dd/MM/yyyy HH:mm:ss'
  | 'dd/MM/yyyy'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'dd.MM.yyyy HH:mm:ss';
export const getFormattedDate = (
  dateTimeString: string,
  format: TFormatDate = 'yyy-MM-dd'
): string | null => {
  // Если строка даты не задана, возвращаем null
  if (!dateTimeString) return null;

  try {
    // Парсим строку даты в объект Date
    const dateObject = parseISO(dateTimeString);
    // Форматируем дату в заданном формате с учетом локали
    const res = formatDate(dateObject, format, { locale: ru });
    return res; // Возвращаем отформатированную строку даты
  } catch (error) {
    // В случае ошибки можно обработать ее или просто вернуть null
    return null;
  }
};
