export const ROLE_USER = [
  {
    value: 'Администратор',
    label: 'Admin'
  },
  {
    value: 'Менеджер',
    label: 'Manager'
  },
  {
    value: 'Агент',
    label: 'Agent'
  }
];
