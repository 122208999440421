import { GridColDef } from '@mui/x-data-grid';

import { TApps } from 'shared/services/types/apps.types';

export const COLUMNS_APPS: GridColDef<TApps.AppData>[] = [
  {
    field: 'version',
    headerName: 'Version',
    minWidth: 265,
    width: 235 / 17.9
  },
  {
    field: 'created_at',
    headerName: 'Upload date',
    minWidth: 239,
    width: 209 / 17.9
  },
  {
    field: 'user_id',
    headerName: 'Email',
    minWidth: 320,
    width: 290 / 17.9
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 792,
    // width: 900 / 17.9
    width: 100
  },
  {
    field: 'knopochki',
    headerName: '',
    maxWidth: 104
  }
];
