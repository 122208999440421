import { FC, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { TooltipProps } from 'recharts';

import { secondsToTimeString } from 'widgets/statistics/utils';

import { formatNumber } from 'shared/utils';

import { TChart } from '../../chart.types';

type CustomBarProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
};

interface CustomTooltipProps extends TooltipProps<any, any> {
  active?: boolean;
  isTime?: boolean;
  lines?: TChart.Lines[];
}

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, lines, isTime }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        className='custom-tooltip'
        sx={{ backgroundColor: 'inherit', border: 'none', padding: '6px 12px' }}>
        {payload.map((entry, index) => {
          const backgroundColor = hexToRgba(entry.color!, 0.2);
          const label = lines?.[index].label;

          const value = isTime ? secondsToTimeString(entry.value) : `$${formatNumber(entry.value)}`;

          return (
            <Box
              component={'p'}
              key={`item-${index}`}
              style={{
                backdropFilter: 'blur(10px)', // Размытие фона
                background: backgroundColor,
                padding: '10px',
                color: '#44464F',
                fontWeight: 500,
                borderRadius: '12px'
              }}>
              {`${label}: ${value}`}
            </Box>
          );
        })}
      </Box>
    );
  }

  return null;
};

export const CustomBar = ({ x, y, width, height, fill }: CustomBarProps) => {
  // Если высота отрицательная, изменяем y и height
  const adjustedY = height < 0 ? y + height : y; // Если height отрицательный, поднимаем y
  const adjustedHeight = Math.abs(height); // Делаем высоту положительной

  return (
    <g>
      <rect
        x={x}
        y={adjustedY}
        width={width}
        height={adjustedHeight}
        cursor={'pointer'}
        fill={fill}
        rx={5}
        ry={5}
      />
    </g>
  );
};

type CustomizedLabelProps = {
  x: number;
  y: number;
  value: number;
};

export const CustomizedLabel = ({ x, y, value }: CustomizedLabelProps) => {
  return (
    <text x={x + 20} y={y - 10} fill='#7B7B7B' fontSize={11} fontWeight={600} textAnchor='middle'>
      ${formatNumber(value)}
    </text>
  );
};

type CustomXAxisTickProps = {
  x: number;
  activeIndex: number;
  hideTick?: boolean;
  y: number;
  index: number;
  visibleTicksCount: number;
  onChangeActive?: (val: null, index: number) => void;

  payload: {
    value: string;
  };
};

export const CustomXAxisTick = ({
  x,
  y,
  payload,
  onChangeActive,
  activeIndex,
  hideTick,
  ...rest
}: CustomXAxisTickProps) => {
  const isActive = rest.visibleTicksCount - 1 === rest.index;
  const textRef = useRef<SVGTextElement>(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getComputedTextLength();

      setTextWidth(width + 10);
    }
  }, [payload.value]);

  if (hideTick) return null;

  const rectHeight = 24;
  const rectX = x - (textWidth + 20) / 2;
  const rectY = y - rectHeight + 12;

  return (
    <>
      {isActive && (
        <rect
          x={rectX}
          y={rectY + 9}
          width={textWidth + 8}
          height={rectHeight}
          fill={isActive ? '#185AC2' : '#ccc'}
          rx={12}
          ry={12}
          onClick={() => onChangeActive?.(null, rest.index)}
        />
      )}

      <text
        ref={textRef}
        x={isActive ? x - 6 : x}
        y={rectY + 10}
        fontWeight={500}
        dy={16}
        fill={isActive ? '#fff' : '#878787'}
        fontSize={14}
        textAnchor='middle'>
        {payload.value}
      </text>
    </>
  );
};
