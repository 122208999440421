import { To } from 'react-router-dom';
import { create } from 'zustand';

interface StoreState {
  isNavigate: boolean;
  setIsNavigate: (isNavigate: boolean) => void;
  isWarningModal: boolean;
  setIsWarningModal: (isWarningModal: boolean) => void;
  path: To | number;
  setPath: (path: To | number) => void;
}

const useNavigateStore = create<StoreState>()(set => ({
  isNavigate: true,
  setIsNavigate: isNavigate => set({ isNavigate: isNavigate }),
  isWarningModal: false,
  setIsWarningModal: isWarningModal => set({ isWarningModal: isWarningModal }),
  path: '',
  setPath: path => set({ path: path })
}));

export default useNavigateStore;
