import { FC, useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormWrapper } from 'shared/ui/form-wrapper';
import { SelectSearch } from 'shared/ui/select-multi';
import { addMessageToast } from 'shared/utils';

interface IProps {
  text: string;
  setRegex: (data: { regex: string; names: string[] }) => void;
}

type TSelection = {
  id: string;
  name: string;
  text: string;
  color: string;
  start: number;
  end: number;
};

export const RegexEditor: FC<IProps> = ({ text, setRegex }) => {
  const methods = useForm();
  const [selectVisible, setSelectVisible] = useState(false);
  const [selections, setSelections] = useState<TSelection[]>([]);
  const [currentSelection, setCurrentSelection] = useState<string | null>(null); // Текущее выделение для обработки
  const [currentStart, setCurrentStart] = useState<number | null>(null);
  const [currentEnd, setCurrentEnd] = useState<number | null>(null);
  const colors = ['#ffb3ba', '#baffc9', '#bae1ff', '#ffdfba', '#ffffba', '#baffb3']; // Цвета для выделений

  const deleteSection = (data: TSelection) => {
    setSelections(prev => prev.filter(item => item.id !== data.id));
  };

  const patterns = {
    text: '(.*?)'
  };

  const handleSelection = () => {
    const selection = window.getSelection();

    if (selectVisible) {
      setCurrentSelection(null);
      setSelectVisible(false);
    }
    if (selection && selection.rangeCount > 0) {
      const selectedText = selection.toString().trim();

      const textarea = textareaRef.current;
      const start = textarea!.selectionStart;
      const end = textarea!.selectionEnd;

      if (selectedText) {
        const exists = selections.some(item => {
          return (start >= item.start && start < item.end) || (end > item.start && end <= item.end);
        });

        if (!exists) {
          setCurrentSelection(selectedText);
          setCurrentStart(start);
          setCurrentEnd(end);
          setSelectVisible(true);
        } else {
          addMessageToast('This section of text is already highlighted.', 'info');
        }
      }
    }
  };

  useEffect(() => {
    const sortedSelections = [...selections].sort((a, b) => {
      const indexA = a.start;
      const indexB = b.start;
      return indexA - indexB;
    });

    if (JSON.stringify(sortedSelections) !== JSON.stringify(selections)) {
      setSelections(sortedSelections);
    }
  }, [selections, text]);

  useEffect(() => {
    const name = methods.watch('name');
    if (currentSelection && name !== null && currentStart !== null && currentEnd !== null) {
      const newId = `selection-${Date.now()}`; // Генерация уникального идентификатора
      setSelections(prevSelections => [
        ...prevSelections,
        {
          id: newId,
          text: currentSelection,
          name: name,
          start: currentStart,
          end: currentEnd,
          color: colors[prevSelections.length % colors.length]
        }
      ]);
      setCurrentSelection(null);
      setCurrentStart(null);
      setCurrentEnd(null);
      setSelectVisible(false);
      methods.setValue('name', null);
    }
  }, [methods.watch('name')]);

  // Генерация регулярного выражения с экранированием
  const generateRegex = () => {
    let modifiedText = text;

    selections.forEach(selection => {
      const pattern = patterns.text; // Используем только паттерн для текста

      // Экранирование выделенного текста, чтобы он корректно вписался в регулярное выражение
      const escapedSelection = selection.text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

      // Заменяем выделенный текст на регулярный паттерн (убираем лишние скобки)
      modifiedText = modifiedText.replace(new RegExp(escapedSelection, 'g'), pattern);
    });

    // Добавляем экранирование символа '/', если он не экранирован
    modifiedText = modifiedText.replace(/(?<!\\)\//g, '\\/');

    // Добавляем экранирование для символа '+'
    modifiedText = modifiedText.replace(/\+/g, '\\+');

    // Устанавливаем флаг 'u' для поддержки Unicode
    modifiedText = modifiedText.replace(/\\u200e/g, '(?:\\u200e)?');

    setRegex({ regex: modifiedText, names: selections.map(item => item.name) });
  };

  useEffect(() => {
    generateRegex();
  }, [selections]);

  // Функция для рендера текста с выделением в HTML
  const renderHighlightedText = () => {
    const parts: Array<string | React.ReactNode> = [];
    let lastIndex = 0;

    // Сортируем выделения по начальному индексу
    const sortedSelections = [...selections].sort((a, b) => a.start - b.start);

    sortedSelections.forEach(selection => {
      // Добавляем текст до выделения
      if (lastIndex < selection.start) {
        parts.push(text.substring(lastIndex, selection.start));
      }

      // Добавляем выделенный текст с подсветкой
      parts.push(
        <span key={selection.id} style={{ backgroundColor: selection.color }}>
          {text.substring(selection.start, selection.end)}
        </span>
      );

      // Обновляем индекс последнего добавленного текста
      lastIndex = selection.end;
    });

    // Добавляем оставшийся текст после последнего выделения
    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSelect = () => {};

  return (
    <Box width={'100%'} position='relative' overflow={'auto'} minWidth={'200px'}>
      <Box
        component='div'
        contentEditable
        suppressContentEditableWarning={true} // Убираем предупреждение о содержимом
        onMouseUp={handleSelection}
        style={{ fontSize: '18px' }}
        sx={{
          position: 'absolute',
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word',
          fontFamily: 'Roboto',
          width: '100%',
          fontSize: '18px',
          lineHeight: '24px',
          letterSpacing: '1.1px',
          color: 'inherit',
          zIndex: 1,
          unicodeBidi: 'bidi-override',
          pointerEvents: 'none'
        }}>
        {renderHighlightedText()}
      </Box>
      {/* Прозрачный textarea для взаимодействия */}
      <Box
        ref={textareaRef}
        component='textarea'
        onSelect={handleSelect}
        value={text}
        onMouseUp={handleSelection}
        placeholder='Вставьте текст сообщения сюда...'
        rows={10}
        sx={{
          width: '100%',
          unicodeBidi: 'bidi-override',
          border: 'none',
          resize: 'none',
          position: 'relative',
          fontFamily: 'Roboto',

          fontSize: '18px',
          lineHeight: '24px',
          p: 0,
          letterSpacing: '1.1px',
          zIndex: 2,
          background: 'transparent', // Прозрачный фон
          color: 'black', // Черный текст для взаимодействия
          ':focus': {
            outline: 'none'
          }
        }}
      />
      <FormWrapper methods={methods} onSubmit={() => {}} padding='0'>
        <SelectSearch
          key={methods.watch('name')}
          methods={methods}
          name='name'
          disabled={!selectVisible}
          inputLabel='Enter a name for the selected text*'
          inputPlaceholder='Selected text'
          options={[
            { label: 'Income', value: 'income' },
            { label: 'Balance', value: 'balance' },
            { label: 'Remarks', value: 'remarks' },
            { label: 'Withdrawal', value: 'withdrawal' },
            { label: 'Any', value: 'any' }
          ]}
        />
      </FormWrapper>

      <Box display={'flex'} gap={'10px'} flexDirection={'column'} mt={'20px'}>
        {/* Отображение выделенных данных */}
        {selections.map((selection, index) => (
          <Box
            key={index}
            sx={{
              textAlign: 'left', // Выравнивание для арабского текста
              direction: 'ltr' // Направление выделенного текста
            }}
            p={'18px 0'}
            display={'flex'}
            gap={'10px'}
            borderBottom={'1px solid #E5E5E6'}
            justifyContent={'space-between'}>
            <Box textTransform={'capitalize'} borderRadius={'100px'} p={'6px '}>
              {selection.name}
            </Box>
            <Box
              borderRadius={'100px'}
              gap={'4px'}
              p={'6px 24px'}
              display={'flex'}
              alignItems={'center'}
              bgcolor={selection.color}
              sx={{
                svg: {
                  width: '100%',
                  height: '100%'
                }
              }}>
              {selection.text}
              <Box
                width={'16px'}
                onClick={() => deleteSection(selection)}
                sx={{ cursor: 'pointer' }}
                height={'16px'}>
                <CloseIcon fontSize={'small'} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
