import { GridColDef } from '@mui/x-data-grid';

import { TCompare } from 'shared/services/types/compare.types';

export const APPLICATIONS_COMPARE: GridColDef<TCompare.ResponseItem>[] = [
  {
    field: 'status',
    headerName: 'application',
    width: 62
  },
  {
    field: 'id',
    headerName: 'Transaction ID',
    maxWidth: 333.25 / 17.9
  },
  {
    field: 'timestamp',
    headerName: 'Date',
    maxWidth: 333.25 / 17.9
  },
  {
    field: 'more',
    headerName: '',
    width: 26
  },
  {
    field: 'divider',
    headerName: '',
    width: 30
  }
];
