import { FC, useEffect, useState } from 'react';

import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { TChart } from '../chart.types';

import { CustomTooltip, CustomXAxisTick } from './customize';

interface IProps {
  data?: TChart.DataLineChartOptions[];
  lines?: TChart.Lines[];
  isTime?: boolean;
  activeData: number;
  onChangeActive?: (index: number) => void;
}

export const ChartLine: FC<IProps> = ({ data, onChangeActive, activeData, lines, isTime }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(activeData);

  useEffect(() => {
    setActiveIndex(activeData);
  }, [activeData]);

  const handleMouseEnter = (_: unknown, index: number) => {
    onChangeActive?.(index);
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width='100%' height={'100%'}>
      <LineChart data={data} margin={{ top: 30, right: 25, left: 25 }}>
        <CartesianGrid strokeDasharray='4 4' horizontal={false} vertical={true} />

        <XAxis
          dataKey='name'
          stroke='#E9E9E9'
          tick={props => (
            <CustomXAxisTick
              {...props}
              hideTick={props.payload.index % 2 !== 0}
              activeIndex={activeIndex}
              onChangeActive={handleMouseEnter}
            />
          )}
        />
        <Tooltip content={<CustomTooltip lines={lines} isTime={isTime} />} />

        {lines?.map(item => (
          <Line
            key={item.dataKey}
            type='monotone'
            dataKey={item.dataKey}
            stroke={item.color}
            y={10}
            strokeWidth={2}
            offset={9}
            // shape={renderLineWithOffset}
            strokeDasharray={item.dash ? '7 2' : '0'} // Условие для пунктирной линии
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
