import { FC, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { useGetARTT } from 'features/statistics/api';

import { StatChartWrapper } from 'entities/stat-chart-wrapper';

import { ARTTIcon } from 'shared/assets/icons';
import { TStatistics } from 'shared/services/types/statistics.types';
import { TChart } from 'shared/ui/chart/chart.types';
import { getFormattedDate } from 'shared/utils';

import { getCurrentDate, timeStringToSeconds } from '../utils';

interface IProps extends TChart.ChartProps {}

export const StatsARTT: FC<IProps> = ({ methodId, hiddenChart, filterMethod }) => {
  const dataLabel = [
    {
      color: '#53359E',
      label: 'Average per day'
    },
    {
      color: '#FF9F0A',
      label: hiddenChart ? '7d' : 'Moving average (7d)'
    }
  ] as const;

  const defaultDate = {
    date_to: getCurrentDate(),
    date_from: getCurrentDate(-14)
  };
  const [filter, setFilter] = useState<TStatistics.RequestART>({
    ...defaultDate
  });
  const [activePeriod, setActivePeriod] = useState(dataLabel.at(-1));
  const { data, isPending } = useGetARTT({ ...filterMethod, method_id: [methodId], ...filter });

  const dataARTT = useMemo(() => {
    const resultItems = data?.items?.map(item => ({
      name: getFormattedDate(item.date, 'dd.MM'),
      daily_avg: timeStringToSeconds(item.daily_avg),
      value: timeStringToSeconds(item.daily_avg),
      moving_avg: timeStringToSeconds(item.moving_avg)
    }));

    return {
      items: resultItems,
      lines: [
        { dataKey: 'daily_avg', color: dataLabel[0].color, label: 'Average per day', dash: false },
        { dataKey: 'moving_avg', color: dataLabel[1].color, label: 'Moving average', dash: true }
      ]
    };
  }, [data]);

  return (
    <Box
      height={'100%'}
      width={'100%'}
      sx={{ minWidth: '427px' }}
      position={'relative'}
      borderRadius={'16px'}
      border={'1px solid #BDC1D2'}>
      <StatChartWrapper
        name='ARTT'
        isLine
        isTime
        activeLabel={'7d'}
        description='Average Request-to-Transaction Time'
        data={dataARTT.items}
        lines={dataARTT.lines}
        hiddenChart={hiddenChart}
        icon={<ARTTIcon />}
        headerExtra={
          <>
            {!hiddenChart && (
              <Box display={'flex'} gap={'16px'}>
                {dataLabel.map(v => {
                  return (
                    <Box
                      onClick={() => setActivePeriod(v)}
                      key={v.label}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      borderRadius={'8px'}
                      gap={'8px'}
                      fontSize={'14px'}
                      fontWeight={500}
                      p={'2px'}>
                      <Box
                        bgcolor={v.color}
                        width={'8px'}
                        height={'8px'}
                        borderRadius={'100%'}></Box>
                      {v.label}
                    </Box>
                  );
                })}
              </Box>
            )}
          </>
        }
      />
    </Box>
  );
};
