import { ChangeEvent, FC, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Stack, TableCell, TableRow } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';

import { UserAccountAddIcon, UserAccountIcon } from 'shared/assets/icons';
import { ROLES, ROLE_USER } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import { TooltipInput } from 'shared/ui/input/slices';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { CustomTooltip } from 'shared/ui/tooltip';

interface IProps {
  id: number;
  row: TUsers.UserData;
  usersData: TUsers.GetUsersSuccess;
  methodsItems?: TMethods.MethodItem[];
  onEditUser: (data: TUsers.UserData) => void;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setModalAccountAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setUserMethodID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
}

export const UsersTableRow: FC<IProps> = ({
  id,
  usersData,
  row,
  methodsItems,
  onEditUser,
  setSwitchItem,
  setModalAccountAdd,
  setUserMethodID,
  setUserID
}) => {
  const { customNavigate } = useCustomNavigate();
  const [checked, setChecked] = useState<boolean>(row.is_active);
  const { user, rolesAccess } = useUser();

  const rowMethod = (row: TUsers.UserData) => {
    if (!row.methods.length) return '';
    if (rolesAccess(['Администратор'], row.role)) return 'All methods';
    if (row.methods.length === 1) return row.methods.map(i => i.name);
    return (
      <Stack direction={'row'} alignItems={'center'} gap={'8px'} sx={{ width: 'max-content' }}>
        <Box>{row.methods?.[0]?.name}</Box>
        <Box
          sx={{
            '.MuiBox-root': {
              boxSizing: 'border-box',
              maxHeight: '24px'
            }
          }}>
          <TooltipInput
            count={row.methods?.length}
            list={row.methods?.map(v => ({ label: v.name, value: v.id }))?.slice(1)}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <TableRow key={JSON.stringify(row)}>
      <TableCell component='th' scope='row'>
        {id + 1 + usersData?.page * usersData?.size}
      </TableCell>
      <TableCell align='left'>{row.email}</TableCell>
      <TableCell component='th' scope='row'>
        {row.name}
      </TableCell>
      <TableCell align='left' sx={{ py: '12px' }}>
        {rowMethod(row)}
      </TableCell>
      <TableCell align='left'>{ROLE_USER.find(item => item.value === row.role)?.label}</TableCell>
      <TableCell align='left' sx={{ cursor: 'pointer' }}>
        <Box
          display={'flex'}
          gap={8}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ color: '#AEAAAB' }}>
          <RoleAccessWrapper accessCondition={ROLES.Account.create} currentRole={user?.role}>
            <RoleAccessWrapper accessCondition={ROLES.User.hasAccount} currentRole={row.role}>
              <CustomTooltip
                title={row.has_accounts ? 'User accounts' : 'Add user accounts'}
                optionsOffset={({ popper }: { popper: ClientRectObject }) => {
                  return [-popper.width * 0.3, 0];
                }}>
                {row.has_accounts ? (
                  <UserAccountIcon
                    onClick={() => {
                      customNavigate(`/user-accounts/${row.id}/${row.methods[0].id}`);
                    }}
                    style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                  />
                ) : (
                  <UserAccountAddIcon
                    onClick={() => {
                      setUserMethodID(row.methods[0].id);
                      setUserID(row.id);
                      setModalAccountAdd(true);
                    }}
                    style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                  />
                )}
              </CustomTooltip>
            </RoleAccessWrapper>
          </RoleAccessWrapper>
          <RoleAccessWrapper accessCondition={ROLES.User.canEdit} currentRole={user?.role}>
            {rolesAccess(ROLES.User.editOnlyAgent) &&
            !rolesAccess(ROLES.User.managerCanEdit, row?.role) ? null : (
              <CustomTooltip
                title={'Edit user'}
                optionsOffset={({ popper }: { popper: ClientRectObject }) => {
                  return [-popper.width * 0.3, 0];
                }}>
                <ModeEditOutlineOutlinedIcon
                  onClick={() => {
                    onEditUser(row);
                  }}
                  style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                />
              </CustomTooltip>
            )}
          </RoleAccessWrapper>
          <CustomTooltip
            title={`Turn off the acceptance of applications and transactions from the user`}
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [-popper.width * 0.47, 0];
            }}>
            <Box>
              <StyledSwitch
                value={!!checked}
                setValue={e => {
                  setSwitchItem(e, row.id);
                  setChecked(!e.target.checked);
                }}
              />
            </Box>
          </CustomTooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};
