import { FC, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { theme } from 'app/provider/material-ui/theme';

import { useGetGeneralInfo } from 'features/statistics/api';

import { StatChartWrapper } from 'entities/stat-chart-wrapper';

import { GeneralIcon } from 'shared/assets/icons';
import { TChart } from 'shared/ui/chart/chart.types';
import { formatNumber } from 'shared/utils';

import { getMonthAndPeriod, secondsToTimeString, timeStringToSeconds } from '../utils';

interface IProps extends TChart.ChartProps {}

export const StatsGeneralInfo: FC<IProps> = ({ methodId, hiddenChart, filterMethod }) => {
  const perMonth = getMonthAndPeriod(-1);
  const perPerMonth = getMonthAndPeriod(-2);
  const dataLabel = [
    {
      value: 'month before last',
      label: perPerMonth.month
    },
    {
      value: 'lath month',
      label: perMonth.month
    },
    {
      value: 'all time',
      label: 'Total'
    }
  ] as const;
  const [activePeriod, setActivePeriod] = useState(dataLabel.at(-1));

  const { data, isPending } = useGetGeneralInfo({
    ...filterMethod,
    method_id: [methodId],
    period: activePeriod?.value
  });

  const dataGeneralInfo = useMemo(() => {
    const approvedPercent = data?.status_percentages?.done || 0;
    const declinedPercent = data?.status_percentages?.declined || 0;
    return [
      {
        name: 'Approved',
        color: '#1DBF73',
        value: approvedPercent.toFixed()
      },
      {
        name: 'Declined',
        color: '#BA1A1A',
        value: declinedPercent.toFixed()
      }
    ];
  }, [data]);

  const dataMetricInfo = useMemo(() => {
    const secondARTT = timeStringToSeconds(data?.artt || '');
    const valueATV = data?.atv;

    return [
      {
        label: 'ARTT',
        value: secondsToTimeString(secondARTT, true)
      },
      {
        label: 'ATV',
        value: `$${formatNumber(valueATV || 0, 0)}`
      }
    ];
  }, [data]);
  const onChangePeriod = (data: any) => {
    setActivePeriod(data);
  };

  return (
    <Box
      height={'100%'}
      width={'100%'}
      sx={{ minWidth: '427px' }}
      position={'relative'}
      borderRadius={'16px'}
      border={'1px solid #BDC1D2'}>
      <StatChartWrapper
        name='General info'
        activeLabel={activePeriod!.label!}
        data={dataGeneralInfo}
        hivePerView
        isVertical
        hiddenChart={hiddenChart}
        icon={<GeneralIcon />}
        bodyExtra={
          <>
            <Box display={'flex'} gap={'4px'} justifyContent={'space-between'} mt={'11px'}>
              {dataMetricInfo.map(v => {
                return (
                  <Box flex={'1 1 50%'} key={v.value}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      fontSize={'14px'}
                      fontWeight={500}
                      color={'#878787'}
                      sx={{
                        lineHeight: '15.5px'
                      }}>
                      {v.label}
                    </Box>
                    <Box
                      fontWeight={600}
                      color={'#080808'}
                      fontSize={'30px'}
                      sx={{
                        span: {
                          fontSize: '20px',
                          color: '#878787'
                        }
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: v.value }} />
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {!hiddenChart && (
              <Box display={'flex'} gap={'3px'}>
                {dataGeneralInfo?.map(v => {
                  return (
                    <Box
                      key={v.name}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      borderRadius={'8px'}
                      gap={'8px'}
                      fontSize={'14px'}
                      fontWeight={500}
                      p={'2px'}>
                      <Box
                        bgcolor={v.color}
                        width={'8px'}
                        height={'8px'}
                        borderRadius={'100%'}></Box>
                      {v.name}
                    </Box>
                  );
                })}
              </Box>
            )}
          </>
        }
        headerExtra={
          <Box display={'flex'} gap={'4px'}>
            {dataLabel.map(v => {
              const active = v.label === activePeriod?.label;

              return (
                <Box
                  onClick={() => onChangePeriod(v)}
                  key={v.value}
                  display={'flex'}
                  alignItems={'center'}
                  fontSize={'13px'}
                  fontWeight={600}
                  sx={{
                    p: '4px 16px',
                    lineHeight: '15.5px',
                    border: active ? '1px solid primary.main' : '1px solid #949497',
                    color: active ? '#fff' : '#949497',
                    background: active ? theme.palette.primary.main : '#94949',
                    borderRadius: '12px',
                    cursor: active ? 'default' : 'pointer'
                  }}>
                  {v.label}
                </Box>
              );
            })}
          </Box>
        }
      />
    </Box>
  );
};
