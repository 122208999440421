const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,17}(\s*)?$/;
const PHONE_BANK =
  /^(?:\s*)?(?:\+)?(?:[- _():=+]?\d[- _():=+]?){10,17}(?:\s*)?$|^(?:\s*)?\d{3,4}(?:\s*)?$/;

const PACKAGE_NAME = /^[a-zA-Z][a-zA-Z0-9._]*[a-zA-Z0-9]$/;

const DATE = /^19|20\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const USERNAME = /^[a-zA-Zа-яА-Я][a-zA-Zа-яА-Я0-9-_.]+$/;

const INTEGER_POS = /^[0-9]+$/;
const FLOAT_POS = /^(?!0\.0)([1-9]\d*(\.\d{1})?|0\.\d{1})$/;

const TWO_FACTOR = /^[0-9]{6}$/;

const APKVERSION = /^(0|[1-9]\d*)\.(\*|(0|[1-9]\d*)\.(\*|(0|[1-9]\d*)))(\r)?$/;

const PASSWORD =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_!#$%^&*()<>?/\|}{~:])[\w@_!#$%^&*()<>?/\|}{~:]{10,}$/;

export const REGEX = {
  EMAIL,
  PHONE,
  DATE,
  USERNAME,
  INTEGER_POS,
  FLOAT_POS,
  TWO_FACTOR,
  APKVERSION,
  PASSWORD,
  PACKAGE_NAME,
  PHONE_BANK
};
