import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';

import { useSwitchMethod } from 'widgets/methods/api';
import { StatsARTT, StatsATV, StatsCTV, StatsGeneralInfo } from 'widgets/statistics';

import { ConfirmChangeStatusMethodModal } from 'entities/confirm-change-status-method';

import { EditIcon, UnfoldMoreIcon } from 'shared/assets/icons';
import { ROLES } from 'shared/constants/rolesAccess';
import { useUser } from 'shared/hooks';
import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { TApplications } from 'shared/services/types/application.types';
import { TMethods } from 'shared/services/types/methods.types';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { CustomTooltip } from 'shared/ui/tooltip';
import { addMessageToast } from 'shared/utils';

interface IProps extends PropsWithChildren {
  methodData: TMethods.MethodItem;
  onOpen: (id: number) => void;
}

export const MethodsItem: FC<IProps> = ({ methodData, onOpen }) => {
  const { customNavigate } = useCustomNavigate();
  const { user, rolesAccess } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const defaultFilter = {
    size: pageSize,
    page: page,
    method_id: methodData.id,
    order_by: 'id',
    is_desc_sort: true
  } as TApplications.GetApplication;

  const [filter, setFilter] = useState(defaultFilter);
  const [checked, setChecked] = useState(methodData.is_active);
  const { switchMethod } = useSwitchMethod();

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(prev => !prev);
    setIsOpen(true);
  };

  const onSubmitChangeMethod = async () => {
    try {
      await switchMethod.mutateAsync({ id: methodData.id });
      checked
        ? addMessageToast('Method successfully activated', 'success')
        : addMessageToast('Method successfully deactivated', 'success');
    } catch (error) {
      addMessageToast(error);
    }
    setIsOpen(false);
  };

  const onFilterHandle = (key: string, value: number) => {
    const newFilter = { ...filter, [key]: value };
    setFilter(newFilter);
  };

  useEffect(() => {
    onFilterHandle('size', pageSize);
  }, [pageSize]);

  useEffect(() => {
    onFilterHandle('page', page);
  }, [page]);

  // const { isApplicationsLoading, applications, isApplicationsError } = useGetApplications(filter);

  return (
    <Box
      p={'14px 24px'}
      display={'flex'}
      flexDirection={'column'}
      gap={'12px'}
      sx={{
        bgcolor: '#fff',
        borderRadius: 4
      }}>
      <Box
        sx={{
          maxWidth: 1,
          gridRow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: '0 15px',
          borderRadius: 8,
          py: 0
        }}>
        <Typography
          variant='h3'
          sx={{
            flexGrow: 1,
            fontSize: 26,
            lineHeight: '24px',
            fontWeight: '500',
            color: '#185AC2'
          }}>
          {methodData.name}
        </Typography>
        <RoleAccessWrapper accessCondition={ROLES.Method.canSwitch} currentRole={user?.role}>
          <CustomTooltip
            title={
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'start'}
                sx={{ fontSize: '16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    maxWidth: '169px',
                    width: '169px',
                    margin: '0 6px',
                    '& span': {
                      fontWeight: 600
                    },
                    '& p': {
                      color: '#878787',
                      fontFamily: 'Roboto',
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '0.25px'
                    }
                  }}>
                  <Typography variant='body1' component='span'>
                    {checked ? 'Turn off method' : 'Turn on method'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    {checked ? 'Turns off auto-matching' : 'Turns on auto-matching'}
                  </Typography>
                </Box>
              </Box>
            }
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [popper.width * 0.1, 0];
            }}>
            <Box>
              <StyledSwitch value={checked} setValue={handleChange} />
            </Box>
          </CustomTooltip>
        </RoleAccessWrapper>

        <Button
          variant='outlined'
          onClick={() => {
            customNavigate(`/method/${methodData.id}`);
          }}
          sx={{
            p: '4px 10px',
            minWidth: '36px'
          }}>
          <UnfoldMoreIcon />
        </Button>
        <RoleAccessWrapper accessCondition={ROLES.Method.canEdit} currentRole={user?.role}>
          <CustomTooltip title='Edit method'>
            <Button
              variant='outlined'
              onClick={() => {
                onOpen(methodData.id);
              }}
              sx={{
                p: '4px 10px',
                minWidth: '36px'
              }}>
              <EditIcon />
            </Button>
          </CustomTooltip>
        </RoleAccessWrapper>
      </Box>
      <Box
        sx={{
          width: '100%',
          gridRow: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          '.MuiPaper-root > div': {
            border: '1px solid #E2EAF7'
          }
        }}>
        {rolesAccess(ROLES.Widgets.canRead) && (
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            justifyContent={'space-between'}
            overflow={'auto'}
            sx={{
              overflowY: 'hidden'
            }}
            height={296}>
            <StatsCTV methodId={methodData.id} />
            <StatsATV methodId={methodData.id} />
            <StatsARTT methodId={methodData.id} />
            <StatsGeneralInfo methodId={methodData.id} />
          </Box>
        )}
      </Box>

      <ConfirmChangeStatusMethodModal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
          setChecked(prev => !prev);
        }}
        checked={checked}
        onSubmit={onSubmitChangeMethod}
      />
    </Box>
  );
};
