import { TAccounts } from 'shared/services/types/accounts.types';

const accountsAvailableOptions = (accounts?: TAccounts.GetAccountSuccess) => {
  const options = accounts?.items.map(account => ({
    label: account.account_id,
    value: account.account_id
  }));

  return options as { label: string | number; value: string | number }[];
};

const getAccountCurrency = (accountID: string, accounts?: TAccounts.GetAccountSuccess) => {
  const account = accounts?.items.find(account => account.account_id === accountID);

  return account?.currency || '';
};

const isAccountActive = (accountID: string, accounts?: TAccounts.GetAccountSuccess) => {
  const account = accounts?.items.find(account => account.account_id === accountID);

  return account?.is_active;
};

const accountBalance = (accountID: string, accounts?: TAccounts.GetAccountSuccess) => {
  const account = accounts?.items.find(account => account.account_id === accountID);
  return account?.balance || null;
};

export { accountBalance, accountsAvailableOptions, getAccountCurrency, isAccountActive };
