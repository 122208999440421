/**
 * Конвертирует номер недели в диапазон дат в формате "MMM dd-dd".
 *
 * @param {number} weekNumber - Номер недели в году (1-52).
 * @returns {string} - Строка с диапазоном дат в формате "MMM dd-dd".
 */
import { addDays, addWeeks, endOfWeek, format, startOfWeek, startOfYear } from 'date-fns';

export const weekNumberToDateRange = (weekNumber: number): string => {
  const currentYear = new Date().getFullYear();
  const startOfYearDate = new Date(currentYear, 0, 1);

  const startOfWeekDate = startOfWeek(addWeeks(startOfYearDate, weekNumber - 1), {
    weekStartsOn: 1
  });

  const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 1 });

  const startDateFormatted = format(startOfWeekDate, 'MMM dd');
  const endDateFormatted = format(endOfWeekDate, 'dd');

  return `${startDateFormatted}-${endDateFormatted}`;
};
