import { GridColDef } from '@mui/x-data-grid';

import { TCompare } from 'shared/services/types/compare.types';

export const TRANSACTIONS_COMPARE: GridColDef<TCompare.ResponseItem>[] = [
  {
    field: 'divider',
    headerName: '',
    width: 30
  },
  {
    field: 'status',
    headerName: 'transactions',
    width: 62,
    align: 'left'
  },
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 333.25 / 17.9
  },
  {
    field: 'timestamp',
    headerName: 'Date',
    maxWidth: 333.25 / 17.9
  },
  {
    field: 'more',
    headerName: '',
    width: 26
  }
];
