import { useEffect, useState } from 'react';

import { Box, Button, IconButton, MobileStepper, Modal, Paper, Typography } from '@mui/material';
import { useAuthStore } from 'app/store';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useCreateTwoFactor, useGetQR } from 'features/auth/api';

import { KeyboardArrow, LogoIcon } from 'shared/assets/icons';
import { REGEX } from 'shared/constants';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { addMessageToast } from 'shared/utils';

import { QrScan } from './qr';
import styles from './two-factor.module.sass';

const TwoFactorAuth = () => {
  const methods = useForm({ mode: 'onBlur' });
  const { checkCode } = useCreateTwoFactor();
  const { toogleOtp } = useAuthStore();
  const onSubmit: SubmitHandler<TAuth.TwoFactorCheck> = async data => {
    try {
      await checkCode.mutateAsync(data);
      toogleOtp(true);
    } catch (error) {
      addMessageToast(error);
    }
  };
  const [isOpen] = useState(true);
  const [isEnabled2fa, setIsEnabled2fa] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    activeStep === 0 && setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    activeStep === 1 && setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const { isQRLoading, getQR, error } = useGetQR();

  useEffect(() => {
    if (error) {
      //@ts-ignore
      setIsEnabled2fa(error?.response?.data?.detail === '2fa already enabled');
      addMessageToast(error);
    }
  }, [error]);

  return (
    <Modal
      open={isOpen}
      hideBackdrop
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={styles.modal}>
      {!isQRLoading ? (
        <Paper className={styles.paper}>
          <LogoIcon
            height={56}
            width={54}
            style={{
              display: 'flex',
              objectFit: 'contain'
            }}
          />
          <FormWrapper onSubmit={onSubmit} methods={methods} padding='0'>
            {activeStep === 0 ? (
              <QrScan isQRLoading={isQRLoading} getQR={getQR} isEnabled2fa={isEnabled2fa} />
            ) : (
              <Box>
                <Typography variant='h5' sx={{ marginTop: '16px', marginBottom: '36px' }}>
                  To verify your identity, enter the code generated by your two-factor
                  authentication application
                </Typography>
                <Input
                  name='code'
                  placeholder='6-digit code'
                  label='Authenticator code*'
                  rules={{
                    required: 'Please, enter 6-digit-code.',
                    pattern: { value: REGEX.TWO_FACTOR, message: 'Please, enter 6-digit-code.' }
                  }}
                  methods={methods}
                />
                <Typography
                  sx={{
                    textAlign: 'start',
                    margin: '6px auto 30px',
                    maxWidth: '333px',
                    fontSize: '12px',
                    lineHeight: '16px'
                  }}
                  variant='body2'>
                  The one-time code can be found in the Authentication app if you have set it up
                </Typography>
                <Button
                  variant='contained'
                  type='submit'
                  fullWidth
                  sx={{ '.MuiButton-startIcon': { marginRight: '6px' } }}>
                  Continue
                </Button>
              </Box>
            )}
          </FormWrapper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              marginTop: '30px'
            }}>
            <IconButton
              disableRipple
              onClick={handleBack}
              sx={{
                p: '0',
                color: activeStep === 0 ? '#90939B' : '#185AC2',
                '&:hover': { background: 'none' }
              }}>
              <KeyboardArrow
                fontSize='large'
                style={{
                  transform: 'rotate(180deg)'
                }}
              />
            </IconButton>
            <Box>
              <MobileStepper
                variant='dots'
                steps={2}
                position='static'
                activeStep={activeStep}
                sx={{
                  flexGrow: 1,
                  backgroundColor: '#fff',
                  p: 0,
                  '.MuiMobileStepper-dot': { width: '10px', height: '10px' }
                }}
                nextButton={null}
                backButton={null}
              />
            </Box>
            <IconButton
              disableRipple
              onClick={handleNext}
              sx={{
                p: '0',
                color: activeStep === 1 ? '#90939B' : '#185AC2',
                '&:hover': { background: 'none' }
              }}>
              <KeyboardArrow fontSize='large' />
            </IconButton>
          </Box>
        </Paper>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default TwoFactorAuth;
