import { FC, useEffect, useState } from 'react';

import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { CustomBar, CustomXAxisTick, CustomizedLabel } from './customize';

interface IProps {
  data: any;
  activeData: number;
  isVertical?: boolean;
  onChangeActive?: (index: number) => void;
}

export const ChartBar: FC<IProps> = ({ data, onChangeActive, activeData, isVertical }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(activeData);

  useEffect(() => {
    setActiveIndex(activeData);
  }, [activeData]);

  const handleMouseEnter = (_: unknown, index: number) => {
    if (isVertical) return null;
    onChangeActive?.(index);
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width='100%' height={'100%'}>
      <BarChart
        data={data}
        margin={{ top: isVertical ? 0 : 20, right: isVertical ? 60 : 0 }}
        layout={isVertical ? 'vertical' : 'horizontal'}>
        {isVertical ? (
          <>
            <YAxis dataKey='name' type='category' hide domain={[0, 100]} />
            <XAxis type='number' stroke='#E9E9E9' fill='#fff' hide />
          </>
        ) : (
          <>
            <XAxis
              dataKey='name'
              tickLine={false}
              angle={10}
              stroke='#E9E9E9'
              tick={props => (
                <CustomXAxisTick
                  {...props}
                  activeIndex={activeIndex}
                  onChangeActive={handleMouseEnter}
                />
              )}
            />
          </>
        )}
        <Bar
          isAnimationActive={true}
          dataKey='value'
          // @ts-ignore
          shape={<CustomBar noClick={isVertical} />}
          background={{ fill: 'white' }}
          onClick={handleMouseEnter}
          cursor={isVertical ? 'default' : 'pointer'}>
          {data?.map((entry: any, index: number) => {
            const fill = activeIndex === index ? '#185AC2' : '#185AC21F';

            return <Cell key={`cell-${index}`} fill={entry.color || fill} cursor='pointer' />;
          })}
          <LabelList
            dataKey='value'
            // @ts-ignore
            content={<CustomizedLabel isVertical={isVertical} activeIndex={activeIndex} />}
            position={'right'}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
