import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MessagesService } from 'shared/services/messages.service';
import { RegexService } from 'shared/services/regex.service';

export const useRegexActions = () => {
  const queryClient = useQueryClient();

  const deleteMessage = useMutation({
    mutationFn: (id: number) => RegexService.deleteRegex(id),
    mutationKey: ['create regex'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get regex'] });
    }
  });
  return { deleteMessage };
};
