import { useState } from 'react';

import { CheckCircleRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useAuthStore } from 'app/store';
import { FieldValues, useForm } from 'react-hook-form';

import { useActionAuth } from 'features/auth/api';

import { LogoIcon } from 'shared/assets/icons';
import { REGEX } from 'shared/constants';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils';

interface IValidForm {
  email?: boolean;
  password?: boolean;
}

export const LoginModal = () => {
  const [resetPass, setResetPass] = useState(false);
  const [, setSubmited] = useState<IValidForm>({
    email: false,
    password: false
  });
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const { loginUser } = useActionAuth();
  const { toogleOtp, updateUser, logout } = useAuthStore();

  const onSubmit = async (data: FieldValues) => {
    setSubmited({ email: true, password: true });
    try {
      const loginData = data as TAuth.Login;
      const response = await loginUser.mutateAsync(loginData);
      updateUser(response.data);
      toogleOtp(response.data.otp_verified);
    } catch (error) {
      addMessageToast(error, 'error');
    }
  };

  return (
    <ModalTemplate
      isConfirmationNeeded={false}
      close={() => {}}
      isDark={false}
      hideCloseIcon
      onBack={
        resetPass
          ? () => {
              methods.reset({});
              setResetPass(false);
              setSubmited({ email: false, password: false });
            }
          : undefined
      }
      showBG
      isOpen
      titleText={resetPass ? 'Reset password' : 'Sing in to CashDash'}
      titleLogo={
        <LogoIcon
          height={56}
          width={54}
          style={{
            display: 'flex',
            objectFit: 'contain',
            margin: '0 auto 16px'
          }}
        />
      }
      sx={{
        'h1.MuiTypography-root': { fontSize: '24px', lineHeight: '32px' },
        'p.MuiTypography-root': { fontSize: '18px', lineHeight: '24px' }
      }}
      hideBackdrop
      subtitleText={
        resetPass
          ? 'Enter your email to reset your password'
          : 'Enter your login and password to sign in'
      }
      reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'} position={'relative'}>
            {!resetPass && (
              <>
                <Grid item sx={{ pt: '34px !important' }}>
                  <Input
                    name='email'
                    rules={{
                      required: true,
                      minLength: { value: 1, message: 'Wrong login' },
                      pattern: {
                        value: REGEX.EMAIL,
                        message: 'Please, enter valid email'
                      }
                    }}
                    label='Login*'
                    placeholder='Your login'
                    methods={methods}
                  />
                </Grid>
                <Grid item sx={{ pt: '30px !important' }}>
                  <Input
                    name='password'
                    type={'password'}
                    rules={{
                      required: true,
                      minLength: { value: 4, message: 'Too short password' }
                    }}
                    label='Password*'
                    placeholder='***'
                    methods={methods}
                  />
                </Grid>
              </>
            )}
            {resetPass && (
              <>
                <Grid item sx={{ marginBottom: '30px' }}>
                  <Input
                    name='email_reset'
                    rules={{
                      required: true,
                      minLength: { value: 4, message: 'Too short password' }
                    }}
                    label='Login*'
                    placeholder='Your login'
                    methods={methods}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {!resetPass && (
            <>
              <Grid
                container
                columns={2}
                wrap={'nowrap'}
                sx={{ justifyContent: 'space-between', margin: '20px 0 30px' }}>
                <Grid item>
                  <FormControlLabel
                    sx={{
                      marginLeft: '-4px',
                      '.MuiTypography-root': {
                        fontSize: 14,
                        lineHeight: '20px',
                        color: '#90939B'
                      },
                      '.MuiSvgIcon-fontSizeMedium': { color: '#BDC1D2', fontSize: 22 },
                      '.Mui-checked .MuiSvgIcon-fontSizeMedium': {
                        color: '#1EBD8D'
                      },
                      '.Mui-checked + .MuiTypography-root': {
                        color: '#44464F'
                      }
                    }}
                    control={
                      <Checkbox
                        value='true'
                        color='success'
                        sx={{ p: 1 }}
                        {...methods.register('checkbox_remember')}
                        icon={<RadioButtonUncheckedRounded />}
                        checkedIcon={<CheckCircleRounded />}
                      />
                    }
                    label='Remember me'
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Button
          type='submit'
          fullWidth
          disabled={loginUser.isPending}
          variant='contained'
          // startIcon={!resetPass && <Add />}
          sx={{ m: 0, borderRadius: 50, textTransform: 'none', py: '8px' }}
          onClick={() => {
            setSubmited({ email: true, password: true });
          }}>
          {resetPass ? 'Reset password' : 'Sign In'}
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
