import { addMonths, format } from 'date-fns';

/**
 * Получает название месяца и его период относительно текущей даты.
 *
 * @param {number} offset - Смещение от текущего месяца.
 *                          Например, -1 для предыдущего месяца, 0 для текущего, 1 для следующего.
 * @returns {{ month: string, period: string, from_date: string, to_date: string }}
 *          Объект, содержащий название месяца, период, дату начала и дату конца месяца.
 */
export const getMonthAndPeriod = (offset: number) => {
  const currentDate = new Date();
  const targetDate = addMonths(currentDate, offset);

  const monthName = format(targetDate, 'MMMM'); // Название месяца
  const year = targetDate.getFullYear(); // Текущий год
  const startOfMonth = format(new Date(year, targetDate.getMonth(), 1), 'dd.MM.yyyy'); // Начало месяца
  const endOfMonth = format(new Date(year, targetDate.getMonth() + 1, 0), 'dd.MM.yyyy'); // Конец месяца

  const period = `${startOfMonth} - ${endOfMonth}`; // Период месяца

  return { month: monthName, period, from_date: startOfMonth, to_date: endOfMonth };
};
