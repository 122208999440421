import { GridColDef } from '@mui/x-data-grid';

import { TBanks } from 'shared/services/types/banks.types';

const FULL_TABLE_WIDTH = 1790;

export const COLUMNS_USERS: GridColDef<TBanks.BankData>[] = [
  {
    field: 'id',
    headerName: '№',
    width: 317 / FULL_TABLE_WIDTH
  },
  {
    field: 'name',
    headerName: 'Bank name',
    width: 333 / FULL_TABLE_WIDTH
  },

  {
    field: 'method_id',
    headerName: 'Method',
    width: 346 / FULL_TABLE_WIDTH
  },
  {
    field: 'package_name',
    headerName: 'Appdata package',
    width: 330 / FULL_TABLE_WIDTH
  },
  {
    field: 'phone',
    headerName: 'Phone number',
    width: 247 / FULL_TABLE_WIDTH
  },
  {
    field: 'edit',
    headerName: '',
    width: 128 / FULL_TABLE_WIDTH
  }
];
