import { FC, useEffect, useState } from 'react';

import { Box, Button, Grid, Stack } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ApkUploadIcon } from 'shared/assets/icons';
import { REGEX } from 'shared/constants';
import { TApps } from 'shared/services/types/apps.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { UploadFile } from 'shared/ui/upload-file';
import { hasFormValuesChanged } from 'shared/utils';
import { addMessageToast } from 'shared/utils/addMessageToast';

import { useActionApps } from '../api/useActionApps';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalType: '' | 'description' | 'edit' | 'delete';
  appData: Partial<TApps.AppData>;
  onClose: () => void;
  isApkLoading: boolean;
}

export const AddAppModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  modalType,
  appData,
  onClose,
  isApkLoading
}) => {
  const methods = useForm({ mode: 'onBlur' });
  const { control, reset, register } = methods;
  const formErrors = methods.formState.errors;
  const [files, setFiles] = useState<File[]>([]);
  const { create, remove, update } = useActionApps();
  const [base64String, setBase64String] = useState<string | ArrayBuffer | null>(null);

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch());

  useEffect(() => {
    if (isOpen && appData) {
      reset(appData);
    } else {
      reset({});
    }
  }, [isOpen, appData]);

  const onSubmit: SubmitHandler<TApps.CreateApp> = async data => {
    try {
      if (!appData) {
        await create.mutateAsync(data);
        addMessageToast('App created', 'success');
      } else {
        await update.mutateAsync(data);
        addMessageToast('App updated', 'success');
      }
      setIsOpen(false);
      reset();
    } catch (error) {
      addMessageToast(error);
    }
  };

  const onSubmitDelete = async (data: TApps.DeleteApp) => {
    try {
      await remove.mutateAsync(data);
      setIsOpen(false);
      addMessageToast('Account successfully deleted', 'success');
      methods.reset();
    } catch (error) {
      addMessageToast(error);
    }
  };

  switch (modalType) {
    case 'description':
      return (
        <ModalTemplate
          isConfirmationNeeded={isConfirmationNeeded}
          close={onClose}
          isOpen={isOpen}
          titleText={'Description of version ' + (appData?.version ?? '')}
          hideBackdrop={false}
          maxWidth={535}
          reset={reset}
          sx={{
            '.MuiPaper-root': { padding: '30px 24px', maxHeight: '512px' },
            '.modal-title-wrapper': { padding: 0 },
            '.modal-title': { fontSize: '24px', textAlign: 'left' }
          }}>
          <Box
            sx={{
              fontFamily: 'Roboto',
              textAlign: 'left',
              padding: '14px 0',
              fontSize: '18px',
              color: 'text.secondary',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
              overflow: 'auto',
              maxHeight: '418px'
            }}>
            {appData?.description}
          </Box>
        </ModalTemplate>
      );
    case 'delete':
      return (
        <ModalTemplate
          isConfirmationNeeded={isConfirmationNeeded}
          close={onClose}
          isOpen={isOpen}
          titleText={'Do you really want to delete app ' + (appData?.version ?? '') + '?'}
          reset={reset}
          sx={{
            '.MuiPaper-root': { padding: '30px 24px', maxWidth: '365px' },
            '.modal-title-wrapper': { padding: 0 },
            '.modal-title': { fontSize: '24px' }
          }}>
          <Box sx={{ textAlign: 'center', padding: '30px 0 0' }}>
            <Box display={'flex'} gap='24px' p={'0'} justifyContent='center'>
              <Button
                fullWidth
                color='info'
                variant='contained'
                onClick={onClose}
                sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 170 }}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onSubmitDelete(appData);
                }}
                fullWidth
                variant='contained'
                color='error'
                sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 170 }}>
                Delete
              </Button>
            </Box>
          </Box>
        </ModalTemplate>
      );
    default:
      return (
        <ModalTemplate
          isConfirmationNeeded={isConfirmationNeeded}
          close={onClose}
          isOpen={isOpen}
          titleText={appData ? 'App edit' : 'Add app'}
          reset={reset}>
          <FormWrapper onSubmit={onSubmit} methods={methods}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                <Grid item>
                  <Input
                    name='version'
                    label='Version *'
                    placeholder='Text'
                    autofill={!!appData}
                    rules={{
                      required: 'Please, enter app version',
                      pattern: {
                        value: REGEX.APKVERSION,
                        message: `Enter correct app version, example 0.0.1`
                      }
                    }}
                    methods={methods}
                  />
                </Grid>
                <Grid item>
                  <Input
                    methods={methods}
                    name='description'
                    label='Description *'
                    placeholder='Text'
                    rows={7}
                    multiline
                    notClearable
                  />
                </Grid>
                {!appData && (
                  <Grid
                    item
                    sx={{
                      marginTop: '-14px'
                    }}>
                    <UploadFile
                      methods={methods}
                      label='Upload apk file'
                      uploadIcon={<ApkUploadIcon />}
                      name='file'
                      maxSize={100}
                      availableExtensions={['.apk', '.vnd.android.package-archive']}
                      rules={{ required: 'Please, upload apk file' }}
                      infoTooltip={
                        <Stack>
                          <span>App format: .APK;</span>
                          <span>{`Volume: < 100mb`}</span>
                        </Stack>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box display={'flex'} gap='10px' p={'30px 0 0 '} justifyContent='center'>
              <Button
                fullWidth
                color='info'
                variant='contained'
                onClick={onClose}
                sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 174 }}>
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={isApkLoading}
                fullWidth
                variant='contained'
                sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 174 }}>
                Save
              </Button>
            </Box>
          </FormWrapper>
        </ModalTemplate>
      );
  }
};
