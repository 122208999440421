import { GridColDef } from '@mui/x-data-grid';

import { TCompare } from 'shared/services/types/compare.types';

export const COMPARE: GridColDef<TCompare.ResponseItem>[] = [
  {
    field: 'income',
    headerName: 'Income',
    maxWidth: 154.2 / 17.9,
    hideable: false
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    maxWidth: 154.2 / 17.9,
    hideable: false
  },
  {
    field: 'subagent_id',
    headerName: 'Sub ID',
    maxWidth: 154.2 / 17.9,
    hideable: false
  },
  {
    field: 'account_id',
    headerName: 'Account',
    maxWidth: 154.2 / 17.9,
    hideable: false
  },
  {
    field: 'user_id',
    headerName: 'User ID',
    maxWidth: 154.2 / 17.9,
    hideable: false
  },
  {
    field: 'settings',
    headerName: '',
    width: 29
  }
];
