import { createBrowserRouter } from 'react-router-dom';

import { AccountsPage } from 'pages/accounts';
import AppsPage from 'pages/apps';
import BanksPage from 'pages/banks';
import { Login } from 'pages/login';
import MethodPage from 'pages/method';
import MethodsPage from 'pages/method/[id]';
import { TwoFactor } from 'pages/two-factor';
import UsersPage from 'pages/users';

import AppLayout from 'shared/ui/app-layout';

import { ProtectedRoute } from './protected-route';

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <MethodsPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/login',
        element: (
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        )
      },
      {
        path: '/two-factor',
        element: (
          <ProtectedRoute>
            <TwoFactor />
          </ProtectedRoute>
        )
      },
      {
        path: '/method',
        element: (
          <ProtectedRoute>
            <MethodPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/method/:method_id',
        element: (
          <ProtectedRoute>
            <MethodPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/banks',
        element: (
          <ProtectedRoute>
            <BanksPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/users/:method_id?',
        element: (
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/apps',
        element: (
          <ProtectedRoute>
            <AppsPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/user-accounts/:user_id/:method_id',
        element: (
          <ProtectedRoute>
            <AccountsPage />
          </ProtectedRoute>
        )
      }
    ]
  }
]);
