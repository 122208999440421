import { FC, useState } from 'react';

import { IStepProps } from '..';
import { Box } from '@mui/material';

import { RegexEditor } from 'widgets/regex-editor';

import { CustomButton } from 'shared/ui/custom-button';
import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';

interface IProps extends IModalProps, IStepProps {
  texts: string[];
  setRegexInfo: (data: any) => void;
}

export const MessageParsingModal: FC<IProps> = ({
  close,
  isOpen,
  onBack,
  onNext,
  texts,
  setRegexInfo
}) => {
  const [regex, setRegex] = useState<Record<string, any>>({});
  const regexIsSelect = Object.values(regex)?.filter((v: any) => v.names.length);

  const onNextStep = () => {
    setRegexInfo(regex);
    onNext?.();
  };

  return (
    <ModalTemplate
      maxWidth={texts.length < 3 ? 701 : 1236}
      isConfirmationNeeded
      close={close}
      isOpen={isOpen}
      titleText={'Message parsing'}
      onBack={onBack}>
      <Box p={'30px 24px'} pb={'6px'}>
        <Box
          display={'flex'}
          gap={'20px'}
          overflow={'auto'}
          pb={'90px'}
          sx={{
            '&::-webkit-scrollbar': {
              width: '1px',
              height: '6px',
              backgroundColor: '#8BACE033'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#8BACE0CC',
              borderRadius: '16px',
              height: '1px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#8BACE033',
              borderRadius: '16px'
            }
          }}>
          {texts.map((item, i) => (
            <Box
              minWidth={'381px'}
              maxWidth={texts.length < 3 ? '100%' : '381px'}
              key={item}
              width={'100%'}
              borderRight={texts?.length - 1 !== i ? '1px solid #E5E5E6' : undefined}
              paddingRight={'16px'}>
              <RegexEditor
                text={item}
                setRegex={data => setRegex(prev => ({ ...prev, [i]: data }))}
              />
            </Box>
          ))}
        </Box>
        <Box
          display={'flex'}
          gap='10px'
          width={'calc(100% - 48px)'}
          position={'absolute'}
          bottom={'30px'}>
          <CustomButton disabled={regexIsSelect.length < texts.length} onClick={onNextStep}>
            Confirm
          </CustomButton>
        </Box>
      </Box>
    </ModalTemplate>
  );
};
