import { toFormData } from 'axios';

import { axiosInstance } from 'shared/api';
import { getAppsUrl } from 'shared/api/config/api.config';

import { TApps } from './types/apps.types';

export const AppsService = {
  async getApps(data: TApps.GetApps) {
    const response = await axiosInstance.get<TApps.GetAppsSuccess>(getAppsUrl('/get-list'), {
      params: data
    });

    return response;
  },
  async getLatestApp(data: TApps.GetLatestApps) {
    const response = await axiosInstance.get<TApps.GetAppsSuccess>(getAppsUrl('/latest-version'), {
      params: data
    });

    return response;
  },
  async createApp(data: TApps.CreateApp) {
    const { file: paramsFile, ...versDesc } = data;
    const response = await axiosInstance.post<TApps.CreateApp>(
      getAppsUrl('/apk'),
      toFormData({ file: paramsFile }),
      {
        params: versDesc
      }
    );

    return response;
  },

  async deleteApp(data: TApps.DeleteApp) {
    const response = await axiosInstance.delete<TApps.DeleteApp>(getAppsUrl('/delete-version'), {
      params: data
    });

    return response;
  },

  async downloadApp(data: TApps.DownloadApp) {
    const response = await axiosInstance.get(getAppsUrl('/get-apk-by-version'), {
      responseType: 'blob',
      params: data
    });

    return response;
  },

  async updateApp(data: TApps.UpdateApp) {
    const response = await axiosInstance.patch<TApps.AppData>(
      getAppsUrl('/update-description'),
      {},
      { params: data }
    );

    return response;
  }
};
