import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { StatisticsService } from 'shared/services/statistics.service';
import { TStatistics } from 'shared/services/types/statistics.types';

const oneDay = 24 * 60 * 60 * 1000;
export const useGetCTV = (data: TStatistics.RequestCTV) => {
  const response = useQuery({
    queryFn: () => StatisticsService.getStatCTV(data),
    queryKey: ['get ctv', data],
    retry: 0,
    staleTime: oneDay,
    select: ({ data }) => data,
    // enabled: !!data.method_id,
    placeholderData: keepPreviousData
  });

  return response;
};

export const useGetGeneralInfo = (data: TStatistics.RequestGeneral) => {
  const response = useQuery({
    queryFn: () => StatisticsService.getStatGeneral(data),
    queryKey: ['get general', data],
    retry: 0,
    staleTime: oneDay,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return response;
};

export const useGetARTT = (data: TStatistics.RequestART) => {
  const response = useQuery({
    queryFn: () => StatisticsService.getStatARTT(data),
    staleTime: oneDay,
    queryKey: ['get artt', data],
    retry: 0,
    select: ({ data }) => data,
    // enabled: !!data.method_id,
    placeholderData: keepPreviousData
  });

  return response;
};

export const useGetATV = (data: TStatistics.RequestART) => {
  const response = useQuery({
    queryFn: () => StatisticsService.getStatATV(data),
    staleTime: oneDay,
    queryKey: ['get atv', data],
    retry: 0,
    select: ({ data }) => data,
    // enabled: !!data.method_id,
    placeholderData: keepPreviousData
  });

  return response;
};
