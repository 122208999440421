import { forwardRef } from 'react';

import { THeaderProps } from '..';
import { Add } from '@mui/icons-material';
import { Button, Grid, Paper, PaperProps } from '@mui/material';

interface IProps extends PaperProps {
  headerProps?: THeaderProps;
  isFooter?: boolean;
  children?: React.ReactNode;
}

export const CustomListbox = forwardRef<HTMLDivElement, IProps>(
  ({ headerProps, isFooter, ...props }, ref) => {
    return (
      <Paper
        {...props}
        role='listbox'
        sx={{
          maxHeight: '200px', // Задайте максимальную высоту
          padding: '0 !important',
          border: '1px solid #185AC2 !important'
        }}
        elevation={0}>
        {!!headerProps && (
          <Grid
            container
            sx={{
              padding: '4px 16px',
              borderBottom: '1px solid rgba(24, 90, 194, 0.08)',
              gap: '10px'
            }}
            justifyContent='space-between'>
            <Button
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: '0',
                fontSize: '16px',
                '&:hover': { backgroundColor: 'unset' }
              }}
              endIcon={<Add />}
              onClick={headerProps.onClick}>
              {headerProps.label}
            </Button>
          </Grid>
        )}
        {props.children}

        {isFooter && (
          <Grid container sx={{ padding: '10px 16px', gap: '10px' }} justifyContent='space-between'>
            <Grid
              item
              sx={{
                color: 'rgba(29, 27, 32, 0.50)',
                fontDamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0.04px'
              }}>
              <Button sx={{ padding: 0 }}>Cancel</Button>
            </Grid>
            <Grid
              item
              sx={{
                color: 'rgba(24, 90, 194, 0.20)',
                fontDamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0.04px'
              }}>
              <Button sx={{ padding: 0 }}>Ok</Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
);
