/**
 * Преобразует количество секунд в строку формата "Xh Ym Zs" или "Ym Zs".
 *
 * @param {number} totalSeconds - Общее количество секунд.
 * @param {boolean} [useSpan=false] - Флаг, указывающий, нужно ли оборачивать единицы времени в <span>.
 * @returns {string} Строка времени в формате "Xh Ym Zs" или "Ym Zs".
 */

export const secondsToTimeString = (totalSeconds: number, useSpan: boolean = false): string => {
  const hours = Math.floor(totalSeconds / 3600); // Вычисляем часы
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Вычисляем минуты
  const seconds = totalSeconds % 60; // Вычисляем оставшиеся секунды

  // Форматируем строку в зависимости от наличия часов
  let timeString: string;
  if (hours > 0) {
    timeString = `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
  } else {
    timeString = `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
  }

  // Если useSpan установлен в true, оборачиваем единицы времени в <span>
  if (useSpan) {
    return timeString.replace(/(\d{2})([hms])/g, '$1<span>$2</span>');
  }

  return timeString;
};
