import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { RegexService } from 'shared/services/regex.service';

export const useGetRegex = (methodId?: number) => {
  const response = useQuery({
    queryFn: () => RegexService.getRegexByMethod(methodId!),
    queryKey: ['get regex', methodId],
    retry: 0,
    enabled: !!methodId,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return response;
};
