import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { TUsers } from 'shared/services/types/users.types';
import { UsersService } from 'shared/services/users.service';

export const useGetUsers = (data: TUsers.GetUsers) => {
  const {
    isLoading: isUsersLoading,
    data: usersData,
    isError: isUsersError,
    isFetching: isUsersFetching,
    ...restData
  } = useQuery({
    queryFn: () => UsersService.getUsers({ ...data, is_desc_sort: false }),
    queryKey: ['get users', data],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return { isUsersLoading, usersData, isUsersError, isUsersFetching, ...restData };
};
