import { FC, useEffect, useMemo, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { TMethods } from 'shared/services/types/methods.types';
import { TRegex } from 'shared/services/types/regex.types';
import { addMessageToast } from 'shared/utils/addMessageToast';

import { useCreateMethod, useGetRegex, useRegexActions } from '../api';

import { MainInfoModal, MessageParsingModal, SelectedTextModal } from './slices';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  methodData?: TMethods.MethodItem;
}

export interface IStepProps {
  onBack?: () => void;
  onNext?: () => void;
}
export const AddMethodModal: FC<IProps> = ({ onClose, isOpen, methodData }) => {
  const { customNavigate } = useCustomNavigate();
  const [stepMainForm, setStepMainForm] = useState<number>(0);
  const [step, setStep] = useState('main');
  const { deleteMessage } = useRegexActions();
  const { data: dataRegex } = useGetRegex(isOpen ? methodData?.id : undefined);
  const [regexList, setRegexList] = useState<TRegex.Data[]>([]);

  const [regexData, setRegexData] = useState<Record<number, { regex: string; names: string[] }>>(
    {}
  );

  const onDeleteRegex = async (id: number | string) => {
    try {
      if (typeof id === 'number') {
        await deleteMessage.mutateAsync(id);
      } else {
        const newRegex = Object.entries(regexData).reduce((acc, [key, value], index) => {
          if (value.regex == id) {
            return acc;
          }

          return { ...acc, [index]: { regex: value.regex, names: value.names } };
        }, {});

        setRegexData(newRegex);
        setRegexList(prev => prev.filter(v => v.pattern !== id));
      }
    } catch (error) {
      addMessageToast(error);
    }
  };

  const [text, setText] = useState<string[]>([]);
  const { create, update } = useCreateMethod();
  const methodsStep1 = useForm({ mode: 'onBlur' });
  const methodsStep2 = useForm({ mode: 'onBlur' });

  const regexString = useMemo(() => methodData?.regex?.[0]?.pattern, [methodData]);

  useEffect(() => {
    const newData = Object.values(regexData).map(v => ({
      pattern: v.regex,
      keys: v.names,
      id: v.regex
    }));
    if (dataRegex) {
      //@ts-ignore
      setRegexList([...dataRegex, ...newData]);
    }
  }, [dataRegex, regexData]);
  useEffect(() => {
    if (!regexList.length && stepMainForm === 1) {
      methodsStep2.setValue('regex', '');
    }
  }, [!!regexList.length, stepMainForm]);

  useEffect(() => {
    if (methodData && isOpen) {
      methodsStep1.reset({
        ...methodData,
        bank_id: methodData.bank_id ? String(methodData.bank_id) : undefined,
        transaction_source: 'SMS/push'
      });

      methodsStep2.reset({ ...methodData, regex: regexString });
    } else {
      setStepMainForm(0);
      setStep('main');
      setRegexData({});
      setText([]);
      methodsStep1.reset({});
      methodsStep2.reset({});
    }
  }, [methodData, isOpen]);

  const onSelectStepModal = (step: string) => {
    setStep(step);
  };

  const onSubmit: SubmitHandler<TMethods.CreateMethod> = async newData => {
    const commonData = {
      ...newData,
      ...methodsStep1.getValues(),
      regex: methodsStep2.getValues().regex,
      compare_field: methodsStep2.getValues().compare_field,
      transaction_source: 'sms_push'
    } as TMethods.CreateMethod;

    const regex = Object.values(regexData).map(item => `${item.regex}:$:${item.names.join(',')}`);

    const regexString = JSON.stringify(regex).replace(/\\\\/g, '\\');

    const requestData = {
      name: commonData.name,
      min_value: commonData.min_value,
      decline_time: commonData.decline_time,
      currency: commonData.currency,
      ussd_command: commonData.ussd_command,
      regex: regexString,
      compare_field: commonData.compare_field,
      bank_id: commonData.bank_id,
      transaction_source: commonData.transaction_source
    } as TMethods.CreateMethod;

    try {
      if (!methodData) {
        const { data: newMethodData } = await create.mutateAsync(requestData);
        addMessageToast('Method successfully added', 'success');

        customNavigate(`/method/${newMethodData.id}`);
      } else {
        const updateData = { ...requestData, id: methodData.id, is_active: methodData.is_active };
        if (regex.length) {
          for (const reg of regex) {
            const regexStr = `[\"${reg}"\]`;
            await update.mutateAsync({ ...updateData, regex: regexStr });
          }
        } else {
          await update.mutateAsync(updateData);
        }

        addMessageToast('Method successfully changed', 'success');
      }
      onClose();
    } catch (error) {
      addMessageToast(error);
    }
  };
  useEffect(() => {
    const reg = Object.values(regexData);
    if (reg?.[0]?.regex && !dataRegex?.length) {
      methodsStep2.clearErrors();
      methodsStep2.setValue('regex', reg?.[0].regex);
    }
  }, [regexData]);

  const modal = {
    main: (
      <MainInfoModal
        methodData={methodData}
        formOne={methodsStep1}
        activeTab={stepMainForm}
        formTwo={methodsStep2}
        isLoading={create.isPending || update.isPending}
        onDeleteRegex={onDeleteRegex}
        onNext={() => {
          setStepMainForm(1);
        }}
        regexList={regexList}
        isLoadingRegex={deleteMessage.isPending}
        isOpen={isOpen}
        close={onClose}
        onSubmit={onSubmit}
        onRegexEditor={() => onSelectStepModal('selectedText')}
      />
    ),
    selectedText: (
      <SelectedTextModal
        method_id={methodData?.id}
        isOpen={isOpen}
        setText={text => setText(text)}
        close={onClose}
        onNext={() => onSelectStepModal('messageParsing')}
        onBack={() => onSelectStepModal('main')}
      />
    ),
    messageParsing: (
      <MessageParsingModal
        isOpen={isOpen}
        texts={text}
        setRegexInfo={setRegexData}
        close={onClose}
        onNext={() => {
          onSelectStepModal('modal');
        }}
        onBack={() => onSelectStepModal('selectedText')}
      />
    )
  };

  //@ts-ignore
  return modal[step] || modal.main;
};
