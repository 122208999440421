import { FC } from 'react';

import { Box } from '@mui/material';

import { ModalTemplate } from 'shared/ui/modal-template';
import { getFormattedDate } from 'shared/utils';

interface IProps {
  message: string;
  time: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MessageModal: FC<IProps> = ({ message, setIsOpen, isOpen, time }) => {
  return (
    <ModalTemplate
      isConfirmationNeeded={false}
      close={() => setIsOpen(false)}
      isOpen={isOpen}
      titleText={'Push Message'}
      reset={() => {}}>
      <Box display={'flex'} flexDirection={'column'} gap={'24px'} p={'24px'}>
        <Box sx={{ textAlign: 'center', fontSize: '18px' }}>{message || 'empty'}</Box>
        <Box sx={{ textAlign: 'center', color: '#44464F' }}>
          {getFormattedDate(time, 'dd/MM/yyyy HH:mm:ss')}
        </Box>
      </Box>
    </ModalTemplate>
  );
};
