/**
 * Форматирует число, удаляя нули после десятичной точки.
 *
 * @param {number} num - Число, которое нужно отформатировать.
 * @returns {string} - Отформатированное число в виде строки.
 */

export const formatNumber = (num: number, countZero?: number): string => {
  // Создаем форматировщик для чисел
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0 || countZero // Установите максимальное количество знаков после запятой
  });

  // Форматируем число и возвращаем результат
  return formatter.format(num);
};
