import { useEffect, useRef, useState } from 'react';

import { formatNumber } from 'shared/utils';

type CustomBarProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  noClick?: string;
};

export const CustomBar = ({ x, y, width, height, fill, noClick }: CustomBarProps) => {
  const adjustedY = height < 0 ? y + height : y - 8;
  const adjustedHeight = Math.abs(height + 8);

  const widthBar = noClick ? width + 8 : width;

  const isMinHeight = noClick ? widthBar < 10 : adjustedHeight < 10;

  return (
    <g>
      <rect
        x={x}
        y={adjustedY}
        width={widthBar}
        height={adjustedHeight}
        cursor={noClick ? 'default' : 'pointer'}
        fill={fill}
        rx={isMinHeight ? 5 : 10}
        ry={isMinHeight ? 5 : 10}
      />
    </g>
  );
};

type CustomizedLabelProps = {
  x: number;
  y: number;
  value: number;
  isVertical?: boolean;
  position?: string;
  width: number;
  height: number;
  activeIndex?: number;
  index?: number;
};

export const CustomizedLabel = ({
  x,
  y,
  value,
  isVertical,
  activeIndex,
  ...rest
}: CustomizedLabelProps) => {
  const fill = '#7B7B7B';
  const positionY = y - 10;
  const fontSize = isVertical ? 16 : 11;
  const isActive = rest.index === activeIndex;

  const valueLabel = isVertical ? `${formatNumber(value)}%` : `$${formatNumber(value)}`;

  if (isVertical) {
    return (
      <text
        x={x + (rest.width || 0) + 12}
        y={y + (rest.height || 0) / 2}
        fill={fill}
        fontSize={fontSize}
        fontWeight={600}
        textAnchor='start'>
        {valueLabel}
      </text>
    );
  }

  if (isActive) return null;

  // Для горизонтальных столбцов - по центру столбца
  const positionX = x + (rest.width || 0) / 2; // Центр столбца

  return (
    <text
      x={positionX}
      y={positionY}
      fill={fill}
      fontSize={fontSize}
      fontWeight={600}
      textAnchor='middle'>
      {valueLabel}
    </text>
  );
};

type CustomXAxisTickProps = {
  x: number;
  activeIndex: number;
  y: number;
  index: number;
  onChangeActive?: (val: null, index: number) => void;

  payload: {
    value: string;
  };
};

export const CustomXAxisTick = ({
  x,
  y,
  payload,
  onChangeActive,
  activeIndex,
  ...rest
}: CustomXAxisTickProps) => {
  const isActive = activeIndex === rest.index;
  const textRef = useRef<SVGTextElement>(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getComputedTextLength();

      setTextWidth(width + 10);
    }
  }, [payload.value]);

  const rectHeight = 24;
  const rectX = x - (textWidth + 20) / 2;
  const rectY = y - rectHeight + 12;

  return (
    <>
      <rect
        x={rectX}
        y={rectY + 9}
        width={textWidth + 20}
        height={rectHeight}
        fill={isActive ? '#185AC2' : '#fff'}
        rx={12}
        ry={12}
        cursor={'pointer'}
        onClick={() => onChangeActive?.(null, rest.index)}
      />
      <text
        pointerEvents={'none'}
        ref={textRef}
        x={x}
        y={rectY + 10}
        dy={16}
        fill={isActive ? '#fff' : '#878787'}
        fontSize={14}
        fontWeight={500}
        textAnchor='middle'
        strokeWidth={2}>
        {payload.value}
      </text>
    </>
  );
};
