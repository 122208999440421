import { useLayoutEffect, useState } from 'react';

import { TRoles } from 'shared/services/types/common.types';

import useAuthStore from '../../app/store/useAuthStore';

const useUser = () => {
  const { user } = useAuthStore();
  const [name, setName] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  const rolesAccess = (roles: TRoles[] | TRoles, roleValue?: TRoles, exclude?: boolean) => {
    if (user) {
      const access = roles.includes(roleValue ?? user.role);
      return exclude ? !access : access;
    }
  };

  useLayoutEffect(() => {
    if (user) {
      setName(user.name);
      setCreatedAt(user?.created_at);
    }
  }, [user]);

  return { createdAt, rolesAccess, name, user };
};
export default useUser;
