/**
 * Преобразует строку формата "00m 24s" в количество секунд.
 *
 * @param {string} timeString - Строка времени в формате "Xm Ys", где X - минуты, Y - секунды.
 * @returns {number} Общее количество секунд.
 */
export const timeStringToSeconds = (timeString: string): number => {
  const timeParts = timeString?.split(' '); // Разделяем строку на части
  let totalSeconds = 0;

  timeParts.forEach(part => {
    if (part.endsWith('m')) {
      const minutes = parseInt(part); // Извлекаем минуты
      totalSeconds += minutes * 60; // Преобразуем минуты в секунды
    } else if (part.endsWith('s')) {
      const seconds = parseInt(part); // Извлекаем секунды
      totalSeconds += seconds; // Добавляем секунды
    }
  });

  return totalSeconds;
};
