import { FC, PropsWithChildren } from 'react';

import { Button, ButtonProps } from '@mui/material';

interface IProps extends ButtonProps, PropsWithChildren {
  mode?: 'outline' | 'delete' | 'main';
  height?: number;
}
export const CustomButton: FC<IProps> = ({
  children,
  disabled,
  onClick,
  type,
  height = 40,
  mode = 'main',
  ...rest
}) => {
  const modifiers = {
    outline: {
      type: 'outlined',
      sx: {
        position: 'relative'
      }
    },
    delete: {
      type: 'contained',

      sx: {
        backgroundColor: '#BA1A1A',
        height: height,
        position: 'relative',
        borderRadius: 50,
        textTransform: 'none',
        marginTop: 15,
        marginBottom: 0,
        color: 'white',
        '&:hover': {
          backgroundColor: '#E23131'
        }
      }
    },
    main: {
      type: 'contained',
      sx: {
        position: 'relative',
        borderRadius: 50,
        textTransform: 'none',
        height: height,
        marginTop: 15,
        marginBottom: 0,
        '&.Mui-disabled': {
          backgroundColor: 'rgba(24, 90, 194, 0.2)',
          color: 'rgba(24, 90, 194, 0.4)'
        }
      }
    }
  } as const;

  const buttonModifiers = modifiers[mode];

  return (
    <Button
      fullWidth
      disabled={disabled}
      variant={buttonModifiers.type}
      onClick={onClick}
      type={type}
      sx={{ ...buttonModifiers.sx, ...rest.sx }}
      {...rest}>
      {children}
    </Button>
  );
};
