import { useNavigateStore } from 'app/store';
import { To, useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { isNavigate, setIsWarningModal, setPath } = useNavigateStore();

  const customNavigate = (path: To | number) => {
    setPath(path);
    if (isNavigate) {
      //@ts-ignore //TODO переделать типы как в useNavigate()
      navigate(path);
      setIsWarningModal(false);
    } else {
      setIsWarningModal(true);
    }
  };
  return { customNavigate };
};
