import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

import { useGetMethods } from 'widgets/methods/api';
import { SideNav } from 'widgets/side-nav';

interface IProps extends PropsWithChildren {
  bankName?: string;
  isSingleMethod?: boolean;
}

export const Layout: FC<IProps> = ({ isSingleMethod, children }) => {
  const { methods } = useGetMethods();

  return (
    <>
      <Box
        display='grid'
        gridTemplateColumns='94px 1fr'
        gridTemplateRows='auto auto 1fr'
        gap={4}
        sx={{
          maxHeight: '100vh',
          height: isSingleMethod ? 'calc(100vh - 24px)' : '100vh'
        }}>
        <SideNav methods={methods} />

        {children}
      </Box>
    </>
  );
};
