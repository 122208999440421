import { FC } from 'react';

import { Box, Button } from '@mui/material';

import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';

interface IProps extends IModalProps {
  checked?: boolean;
  onSubmit: () => void;
}
export const ConfirmChangeStatusMethodModal: FC<IProps> = ({
  close,
  isOpen,
  checked,
  onSubmit
}) => {
  const title = checked
    ? 'Do you really want to turn the method on?'
    : 'Do you really want to turn off the method?';

  return (
    <ModalTemplate
      isConfirmationNeeded={false}
      close={close}
      isOpen={isOpen}
      titleText={title}
      reset={() => {}}
      sx={{
        '.MuiPaper-root': { padding: '24px 30px 24px 30px ' },
        '.modal-title-wrapper': { padding: 0 },
        '.modal-title': { fontSize: '24px' }
      }}>
      <Box display={'flex'} flexDirection={'column'} gap={'24px'} mt={15}>
        <Box sx={{ textAlign: 'center' }}>
          <Box display={'flex'} gap='16px' p={'0'} justifyContent='center'>
            <Button
              fullWidth
              color='info'
              variant='contained'
              onClick={() => close()}
              sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 170 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSubmit();
              }}
              fullWidth
              variant='contained'
              color='primary'
              sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 170 }}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </ModalTemplate>
  );
};
