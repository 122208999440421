import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { useContainerHeight } from 'shared/hooks';
import { useUrlParams } from 'shared/hooks/useUrlParams';
import { TAccounts } from 'shared/services/types/accounts.types';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { TableLayout } from 'shared/ui/table-layout';

import { COLUMNS_ACCOUNTS } from '../mock-data/COL_ACCOUNTS';

import { AccountsTableRow } from './AccountsTableRow';

interface IProps {
  accountsData?: TAccounts.GetAccountByUserAndMethodSuccess | TAccounts.GetAccountSuccess;
  isAccountsFetching?: boolean;
  isAccountsLoading?: boolean;
  onEditAccount: (data: TAccounts.AccountByUserAndMethodItem | TAccounts.AccountItem) => void;
  methods?: TMethods.MethodItem[];
  userIds?: number[];
  setFilter: React.Dispatch<React.SetStateAction<TAccounts.GetAccountByUserAndMethod>>;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setModalAccountAdd: React.Dispatch<React.SetStateAction<boolean>>;
}
export const TableAccounts: FC<IProps> = ({
  accountsData,
  isAccountsLoading,
  onEditAccount,
  setFilter,
  setSwitchItem
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);

  const { paramsObj } = useUrlParams();
  const page = parseInt(paramsObj.page) - 1;

  useEffect(() => {
    setFilter(prevState => {
      return { ...prevState, size, page };
    });
  }, [size, page]);

  const customTableStyles = {
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        height: '20px',
        padding: '16px 0',
        borderBottom: '1px solid #BDC1D2',
        color: '#000',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    }
  } as SxProps<Theme>;

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip'
      }}>
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden'
        }}>
        <Box
          px={12}
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',
            height: height
          }}
          ref={ref}>
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              ...customTableStyles
            }}>
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              rows={[]}
              columns={COLUMNS_ACCOUNTS}
              total={0}>
              {isAccountsLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {accountsData?.items.map((row, id) => {
                    return (
                      <AccountsTableRow
                        key={id}
                        accountsData={accountsData}
                        id={id}
                        onEditAccount={onEditAccount}
                        row={row}
                        setSwitchItem={setSwitchItem}
                      />
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
