import { FC, useState } from 'react';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, CircularProgress } from '@mui/material';
import { theme } from 'app/provider/material-ui/theme';

import { TSelectOption } from 'shared/services/types/common.types';
import { CustomTooltip } from 'shared/ui/tooltip';

interface IProps {
  count?: number;
  isLoading?: boolean;
  list?: TSelectOption[];
  onDelete?: (id: number | string) => void;
}
export const TooltipInput: FC<IProps> = ({ count = 0, list, onDelete, isLoading }) => {
  const [id, setId] = useState<null | number | string>(null);
  const currentCount = count - 1;
  const onClickDelete = (id: number | string) => {
    if (isLoading) return null;
    setId(id);
    onDelete?.(id);
  };

  if (!count) return null;

  return (
    <>
      <CustomTooltip
        placement='bottom-end'
        optionsOffset={onDelete && [20, 0]}
        disableInteractive={false}
        componentsProps={{
          tooltip: {
            sx: [
              {
                padding: '8px',
                borderRadius: '8px',
                border: '1px solid #BDC1D2',
                maxHeight: '500px',
                //TODO обсудим с деном
                // minWidth: 'max-content',
                maxWidth: '400px',
                overflow: 'auto'
              },
              !onDelete && { padding: '8px 24px' }
            ]
          }
        }}
        title={
          <Box
            display={'flex'}
            flexDirection={'column'}
            flexWrap={'wrap'}
            maxHeight={400}
            gap={onDelete ? '8px' : '5px'}>
            {list?.map(item => (
              <Box
                key={item.value}
                sx={[
                  {
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px'
                  },
                  !!onDelete && {
                    border: '1px solid #BDC1D2',
                    p: '4px 8px',
                    color: '#44464F',
                    borderRadius: '8px',
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontWeight: '400'
                  }
                ]}>
                <span>{item.label}</span>
                {onDelete && (
                  <>
                    {isLoading && item.value === id ? (
                      <Box sx={{ width: '24px', height: '24px', flex: '0 0 24px' }}>
                        <CircularProgress size={'100%'} />
                      </Box>
                    ) : (
                      <CancelOutlinedIcon
                        onClick={() => onClickDelete(item.value)}
                        sx={{
                          cursor: 'pointer'
                        }}
                      />
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>
        }>
        <Box
          sx={{
            background: '#8C8C8C1A',
            color: theme.palette.primary.main,
            borderRadius: '100%',
            p: '4px',
            fontWeight: 700,
            fontSize: 14,
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex',
            minWidth: '16px',
            alignItems: 'center'
          }}>
          {count > 1 ? `+${currentCount}` : 'i'}
        </Box>
      </CustomTooltip>
    </>
  );
};
