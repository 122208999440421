import { useNavigate } from 'react-router-dom';

import { Apps } from 'widgets/apps';

import { Layout } from 'shared/ui/layout';

const AppsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Layout bankName='Apps' isSingleMethod>
        <Apps />
      </Layout>
    </>
  );
};

export default AppsPage;
