import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { TAccounts } from 'shared/services/types/accounts.types';
import { UsersService } from 'shared/services/users.service';

export const useGetAccountsIsAgent = (
  data: TAccounts.GetAccountByUserAndMethod | TAccounts.GetAccount,
  isAgent?: boolean,
  user?: boolean
) => {
  const {
    isLoading: isAccountsLoading,
    data: accountsData,
    isError: isAccountsError,
    isFetching: isAccountsFetching,
    ...restData
  } = useQuery({
    queryFn: () =>
      isAgent
        ? UsersService.getAccounts(data as TAccounts.GetAccount)
        : UsersService.getAccountsByUserAndMethod(data as TAccounts.GetAccountByUserAndMethod),
    queryKey: ['get user accounts', data],
    retry: 0,
    select: ({ data }) => data,
    enabled: user,
    placeholderData: keepPreviousData
  });

  return { isAccountsLoading, accountsData, isAccountsError, isAccountsFetching, ...restData };
};
