import { axiosInstance } from 'shared/api';
import { getRegexUrl } from 'shared/api/config/api.config';
import { createFormData } from 'shared/utils';

import { TRegex } from './types/regex.types';

export const RegexService = {
  async getRegexByMethod(method_id: number) {
    const response = await axiosInstance.get<TRegex.Data[]>(getRegexUrl('/get-regexes-by-method'), {
      params: { method_id }
    });

    return response;
  },
  async deleteRegex(id: number) {
    const response = await axiosInstance.delete<TRegex.Data>(getRegexUrl('/delete-regex'), {
      data: createFormData({ id })
    });

    return response;
  }
};
