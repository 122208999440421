/**
 * Сравнивает значение выбранного элемента массива с предыдущим элементом и вычисляет процентное изменение.
 *
 * @param {string[]} data - Массив строк, содержащих числовые значения.
 * @param {number} selectedIndex - Индекс элемента в массиве, который нужно сравнить с предыдущим элементом.
 *
 * @returns {string | null} - Возвращает процентное изменение в виде строки.
 *                            Если выбранный индекс находится за пределами массива, если нет предыдущего элемента,
 *                            или если значения не могут быть преобразованы в числа, возвращает `null`.
 *
 * @example
 * const data = ["10", "20", "30", "40"];
 * const selectedIndex = 2;
 * const result = compareWithPrevious(data, selectedIndex); // "50"
 *
 * @example
 * const data = ["10", "20", "not a number", "40"];
 * const selectedIndex = 2;
 * const result = compareWithPrevious(data, selectedIndex); // null
 *
 * @example
 * const data = ["10", "20", "30", "40"];
 * const selectedIndex = 0;
 * const result = compareWithPrevious(data, selectedIndex); // null
 */
export function compareWithPrevious(
  data: string[],
  selectedIndex: number,
  otherFormula: boolean
): string | null {
  if (otherFormula) return calculatePercentageChange(data);
  // Проверяем, что выбранный индекс находится в пределах массива
  if (selectedIndex < 0 || selectedIndex >= data.length) {
    return null;
  }

  // Проверяем, что есть предыдущий элемент для сравнения
  if (selectedIndex === 0 || !data.length) {
    return null;
  }

  // Получаем значения выбранного и предыдущего элементов
  const selectedValue = parseFloat(data[selectedIndex]);
  const previousValue = parseFloat(data[selectedIndex - 1]);

  // Проверяем, что значения являются числами
  if (isNaN(selectedValue) || isNaN(previousValue)) {
    return null;
  }

  // Вычисляем процентное изменение
  const percentageChange = ((selectedValue - previousValue) / previousValue) * 100;

  let formattedResult = percentageChange.toFixed(2);

  formattedResult = parseFloat(formattedResult).toString();

  return formattedResult;
}

function calculatePercentageChange(data: string[]) {
  const countDay = -7;
  // Получаем последние 7 значений (N)
  const lastWeekData = data.slice(countDay);
  const N = lastWeekData.reduce((sum, item) => sum + parseFloat(item), 0) / lastWeekData.length;

  // Получаем данные за 7 дней до последней недели (S)
  const previousWeekData = data.slice(-14, countDay);
  if (!previousWeekData.length) {
    return 'None';
  }

  const S =
    previousWeekData.reduce((sum, item) => sum + parseFloat(item), 0) /
    (previousWeekData.length || 7);

  const percentageChange = ((N - S) / S) * 100;

  const formattedResult = percentageChange.toFixed(2);
  const result = parseFloat(formattedResult).toString();
  return result;
}
