import { ChangeEvent, FC, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, TableCell, TableRow } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';

import { ROLES } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { TAccounts } from 'shared/services/types/accounts.types';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { CustomTooltip } from 'shared/ui/tooltip';

interface IProps {
  id: number;
  row: TAccounts.AccountByUserAndMethodItem | TAccounts.AccountItem;
  accountsData: TAccounts.GetAccountByUserAndMethodSuccess | TAccounts.GetAccountSuccess;
  onEditAccount: (data: TAccounts.AccountByUserAndMethodItem | TAccounts.AccountItem) => void;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
}

export const AccountsTableRow: FC<IProps> = ({
  id,
  accountsData,
  row,
  setSwitchItem,
  onEditAccount
}) => {
  const { user, rolesAccess } = useUser();
  const [checked, setChecked] = useState<boolean>();
  return (
    <TableRow key={JSON.stringify(row)}>
      <TableCell component='th' scope='row'>
        {++id + accountsData?.page * accountsData?.size}
      </TableCell>
      <TableCell align='left'>{row.account_name}</TableCell>
      <TableCell align='left'>{row.subagent_id}</TableCell>
      <TableCell align='left' sx={{ cursor: 'pointer' }}>
        <Box display={'flex'} gap={8} justifyContent={'flex-end'} sx={{ color: '#AEAAAB' }}>
          <RoleAccessWrapper accessCondition={ROLES.Account.edit} currentRole={user?.role}>
            <CustomTooltip
              title={'Edit account'}
              optionsOffset={({ popper }: { popper: ClientRectObject }) => {
                return [-popper.width * 0.3, 0];
              }}>
              <ModeEditOutlineOutlinedIcon
                onClick={() => {
                  onEditAccount(row);
                }}
                style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
              />
            </CustomTooltip>
          </RoleAccessWrapper>
          <CustomTooltip
            title={`Turn off the acceptance of applications and transactions for the account`}
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [-popper.width * 0.47, 0];
            }}>
            <Box>
              <StyledSwitch
                value={checked ?? row.is_active}
                setValue={e => {
                  setSwitchItem(e, row.id);
                }}
              />
            </Box>
          </CustomTooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};
