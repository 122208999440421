import { addDays, format } from 'date-fns';

import { TFormatDate } from 'shared/utils/getFormattedDate';

/**
 * Получает текущую дату с учетом смещения в днях и формата даты.
 *
 * @param {number | null} [offset=0] - Количество дней для добавления (положительное значение) или вычитания (отрицательное значение).
 * Если не передан или равен null, возвращает сегодняшнюю дату.
 * @param {string} [dateFormat='yyyy-MM-dd'] - Формат, в котором будет возвращена дата.
 * @returns {TFormatDate} - Дата в указанном формате.
 */
export const getCurrentDate = (
  offset: number | null = 0,
  dateFormat: TFormatDate = 'yyy-MM-dd'
): string => {
  // Если offset равен null, возвращаем сегодняшнюю дату
  if (offset === null) {
    return format(new Date(), dateFormat);
  }

  // Вычисляем текущую дату с учетом offset
  const currentDate = addDays(new Date(), offset);
  return format(currentDate, dateFormat);
};

// Примеры использования:
getCurrentDate(); // Сегодняшняя дата в формате 'yyyy-MM-dd'
getCurrentDate(0); // Сегодняшняя дата в формате 'yyyy-MM-dd'
getCurrentDate(null); // Сегодняшняя дата в формате 'yyyy-MM-dd'
getCurrentDate(-14); // Дата 14 дней назад в формате 'yyyy-MM-dd'
getCurrentDate(-14, 'dd/MM/yyyy'); // Дата 14 дней назад в формате 'dd/MM/yyyy'
