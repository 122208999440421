import { FC } from 'react';

import { Box, Button } from '@mui/material';
import useSettingsStore from 'app/store/useNavigateStore';
import { useNavigate } from 'react-router-dom';

import { ModalTemplate } from 'shared/ui/modal-template';

interface IProps {
  isOpen: boolean;
  handleChange: () => void;
}

export const NavigateWarningModal: FC<IProps> = ({ isOpen, handleChange }) => {
  const { setIsNavigate, setIsWarningModal, path } = useSettingsStore();
  const navigate = useNavigate();
  return (
    <ModalTemplate
      close={() => {
        setIsWarningModal(false);
      }}
      isOpen={isOpen}
      sx={{ '.MuiPaper-root': { maxWidth: '413px' }, '.MuiTypography-root': { px: '24px' } }}
      titleText={'Do you really want to leave the page without saving your changes?'}>
      <Box display={'flex'} gap='10px' padding={'0 24px 30px'}>
        <Button
          fullWidth
          variant='contained'
          onClick={() => {
            setIsNavigate(true);
            setIsWarningModal(false);
            typeof path === 'number' ? navigate(path) : navigate(path);
          }}
          color='info'
          sx={{
            borderRadius: 50,
            textTransform: 'none',
            height: 40,
            marginTop: 15
          }}>
          Leave
        </Button>
        <Button
          fullWidth
          variant='contained'
          onClick={() => {
            setIsNavigate(true);
            setIsWarningModal(false);
            typeof path === 'number' ? navigate(path) : navigate(path);
            handleChange();
          }}
          sx={{
            borderRadius: 50,
            textTransform: 'none',
            height: 40,
            marginTop: 15
          }}>
          Save and leave
        </Button>
      </Box>
    </ModalTemplate>
  );
};
