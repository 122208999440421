import { FC, useEffect } from 'react';

import { Box, Button, Grid, Stack } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { REGEX } from 'shared/constants';
import { TBanks } from 'shared/services/types/banks.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { UploadFile } from 'shared/ui/upload-file';
import { addMessageToast, hasFormValuesChanged } from 'shared/utils';

import { useActionsBank } from '../api';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  bankData?: TBanks.BankData;
  onClose: () => void;
  setCreatedBank?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const BankModal: FC<IProps> = ({ isOpen, setIsOpen, bankData, onClose, setCreatedBank }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const bankDataRes = bankData ? { ...bankData, file: bankData.icon_file } : {};
  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), bankDataRes, []);

  useEffect(() => {
    if (bankData && isOpen) {
      reset(bankDataRes);
    } else {
      reset({});
    }
  }, [bankData, isOpen]);

  useEffect(() => {
    const phoneValue = methods.getValues('phone');
    const packageValue = methods.getValues('package');
    if (
      (methods.formState.errors.phone && !packageValue) ||
      (!phoneValue && methods.formState.errors.package)
    ) {
      ['package', 'phone'].map(i =>
        methods.setError(i, {
          type: 'required',
          message: 'One of the fields must be filled in'
        })
      );
    }
  }, [methods.formState.errors.phone?.message, methods.formState.errors.package?.message]);

  const { onCreateBank, onUpdateBank } = useActionsBank();

  const onSubmit: SubmitHandler<TBanks.CreateBank> = async data => {
    try {
      if (!bankData) {
        const { data: bank } = await onCreateBank.mutateAsync(data);
        setCreatedBank?.(bank.id);
        addMessageToast('Bank created', 'success');
      } else {
        await onUpdateBank.mutateAsync({ ...data, id: bankData.id });
        addMessageToast('Bank updated', 'success');
      }
      onClose();
      reset([]);
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={onClose}
      isOpen={isOpen}
      titleText={bankData ? 'Edit bank' : 'Add bank'}
      reset={() => {
        reset();
        methods.clearErrors();
      }}
      maxWidth={413}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid
            container
            direction={'column'}
            columnGap={'20px'}
            rowGap={15}
            wrap={'nowrap'}
            gridTemplateColumns={'1fr '}
            display='grid'
            gridTemplateRows='auto auto 1fr'>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Bank name*'
                name='name'
                rules={{ required: 'Please, enter bank name' }}
              />
            </Grid>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Appdata package'
                name='package'
                rules={{
                  pattern: {
                    value: REGEX.PACKAGE_NAME,
                    message: 'Enter the correct package name'
                  },
                  validate: {
                    requiredAtLeastOne: value => {
                      const phoneValue = methods.getValues('phone');
                      return value || phoneValue ? true : 'One of the fields must be filled in';
                    }
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Phone number'
                name='phone'
                rules={{
                  pattern: {
                    value: REGEX.PHONE_BANK,
                    message: 'Enter the correct phone number'
                  },
                  validate: {
                    requiredAtLeastOne: value => {
                      const packageValue = methods.getValues('package');
                      return value || packageValue ? true : 'One of the fields must be filled in';
                    }
                  }
                }}
              />
            </Grid>
            {!bankData && (
              <Grid item>
                <UploadFile
                  methods={methods}
                  label='Upload bank logo'
                  name='file'
                  isImage
                  maxSize={5}
                  availableExtensions={['.png', '.icon']}
                  infoTooltip={
                    <Stack>
                      <span>Size: 128x128px;</span>
                      <span>Image format: .ICO, .PNG;</span>
                      <span>{`Volume: < 2mb`}</span>
                    </Stack>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          mt={'30px'}
          display={'flex'}
          gap={'16px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              height: 40,
              boxShadow: 'none'
            }}>
            Save
          </Button>
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
