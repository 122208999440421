import { FC, useEffect, useMemo, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';
import { Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { CircleArrowIcon, CloseIcon, KeyPasswordIcon, LockedIcon } from 'shared/assets/icons';
import { generateValidPassword } from 'shared/utils';

import { CustomTooltip } from '../tooltip';

import styles from './input.module.scss';

interface IProps {
  type?: string;
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  methods: UseFormReturn<FieldValues, any, undefined>;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  isPass?: boolean;
  isEdit?: boolean;
  onClickIcon?: () => void;
  autofill?: boolean;
  defaultValue?: string;
  prefilled?: boolean;
  tooltip?: React.ReactNode;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  notClearable?: boolean;
}

export const Input: FC<IProps> = ({
  methods,
  name,
  rules,
  label,
  placeholder,
  required,
  type,
  helperText,
  isPass,
  isEdit,
  onClickIcon,
  autofill,
  defaultValue,
  prefilled,
  tooltip,
  disabled,
  multiline,
  rows,
  notClearable
}) => {
  const { control } = methods;
  const fieldError = methods?.formState.errors[name]?.message?.toString();
  const [showPassword, setShowPassword] = useState(false);
  const [toogler, setToogler] = useState(true);
  const password = useMemo(() => generateValidPassword(10), [toogler]);

  useEffect(() => {
    if (prefilled) methods?.setValue(name, password);
  }, [password]);

  const resetField = () => {
    methods?.setValue(name, '');
  };
  const isRequired = !label?.includes('*') && rules?.required ? '*' : '';

  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            margin='normal'
            name={name}
            fullWidth
            required={required}
            inputProps={{
              className: styles.input
            }}
            disabled={autofill || disabled}
            id={name}
            label={label + isRequired}
            onChange={onChange}
            onBlur={onBlur}
            value={value || ''}
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
              style: {
                color: fieldError && '#b20000'
              }
            }}
            type={isPass ? (showPassword ? 'text' : 'password') : type}
            sx={{
              m: 0,
              p: 0,

              fieldset: {
                border: fieldError && '1px solid #b20000 '
              },
              input: {
                padding: tooltip
                  ? '16.5px 90px 16.5px 14px'
                  : isEdit
                    ? '16.5px 40px 16.5px 14px'
                    : undefined
              },
              '& input.Mui-disabled': {
                '-webkit-text-fill-color': '#44464F'
              }
            }}
            multiline={multiline}
            rows={rows}
          />
        )}
      />
      {/* TODO Можно выделать в отдельный слайс */}
      <Box sx={{ position: 'absolute', right: 16, top: 16, display: 'flex', alignItems: 'center' }}>
        {isPass && (
          <CustomTooltip
            title={
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'start'}
                sx={{ fontSize: '16px' }}>
                <KeyPasswordIcon
                  style={{
                    fontSize: '24px',
                    width: '24px',
                    height: '24px',
                    color: '#878787'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '190px',
                    width: '190px',
                    margin: '0 6px',
                    cursor: 'pointer',
                    '& span': {
                      fontWeight: 600
                    },
                    '& p': {
                      fontFamily: 'Roboto',
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '0.25px',
                      color: '#878787'
                    }
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(password);
                    methods?.setValue(name, password);
                  }}>
                  <Typography variant='body1' component='span'>
                    {password}
                  </Typography>
                  <Typography variant='body1' color={'#878787'} component='p'>
                    Paste and save the generated password
                  </Typography>
                </Box>
                <Box
                  component={'span'}
                  sx={{ padding: '4px', marginTop: '-4px', cursor: 'pointer' }}
                  onClick={() => {
                    setToogler(prev => !prev);
                  }}>
                  <CircleArrowIcon />
                </Box>
              </Box>
            }
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [popper.width * -0.25, 0];
            }}
            sx={{
              '.MuiTooltip-tooltip': {
                padding: '8px 14px'
              }
            }}
            disableInteractive={false}>
            <Box
              sx={{
                maxHeight: '24px',
                background: '#F9FFAF'
              }}>
              <KeyPasswordIcon style={{ margin: '2px 6px 1px 7px', color: '#A3A2A4' }} />
            </Box>
          </CustomTooltip>
        )}
        {!autofill && (
          <>
            {tooltip && <Box sx={{ mr: '10px' }}> {tooltip}</Box>}
            {isEdit ? (
              <IconButton
                sx={{ cursor: onClickIcon ? 'pointer' : 'default', padding: 0 }}
                onClick={onClickIcon}>
                <EditIcon />
              </IconButton>
            ) : (
              !!methods?.watch(name) && (
                <>
                  {isPass && (
                    <IconButton
                      sx={{ padding: 0, marginRight: '4px', marginLeft: '6px' }}
                      aria-label={showPassword ? 'Hide the password' : 'Display the password'}
                      onClick={() => {
                        setShowPassword?.(!showPassword);
                      }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  )}
                  {!notClearable && (
                    <IconButton
                      sx={{ padding: '2px', width: '24px', height: '24px' }}
                      onClick={resetField}>
                      <CloseIcon height={'20px'} />
                    </IconButton>
                  )}
                </>
              )
            )}
          </>
        )}
        {autofill && <LockedIcon />}
      </Box>
      <Box
        style={{
          marginLeft: '16px',
          fontSize: helperText ? 13 : 12,
          color: helperText ? '#49454F' : '#b20000',
          textAlign: 'start',
          fontWeight: helperText ? 600 : 'normal'
        }}>
        {(helperText && <span>{helperText}</span>) || fieldError}
      </Box>
    </Box>
  );
};
