import { useLayoutEffect } from 'react';

import { UserAccounts } from 'widgets/accounts';

import { ROLES } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { Layout } from 'shared/ui/layout';

export const AccountsPage = () => {
  const { rolesAccess } = useUser();
  const isAccess = rolesAccess(ROLES.Account.canRead);
  const { customNavigate } = useCustomNavigate();

  useLayoutEffect(() => {
    if (!isAccess) {
      customNavigate('/');
    }
  }, [isAccess]);

  return (
    <Layout bankName='Users' isSingleMethod>
      <UserAccounts />
    </Layout>
  );
};
