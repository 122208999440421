import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MessagesService } from 'shared/services/messages.service';

export const useMessageActions = () => {
  const queryClient = useQueryClient();

  const deleteMessage = useMutation({
    mutationFn: (id: number) => MessagesService.deleteMessage(id),
    mutationKey: ['create messages'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get messages'] });
    }
  });
  return { deleteMessage };
};
