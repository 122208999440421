import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { AddAppModal } from 'features/add-app';
import { useActionApps } from 'features/add-app/api/useActionApps';
import { useGetApps } from 'features/add-app/api/useGetApps';

import { ROLES } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { TApps } from 'shared/services/types/apps.types';
import { HeaderWrapper } from 'shared/ui/header-wrapper';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { addMessageToast } from 'shared/utils';

import { TableApps } from './slices/TableApps';

export const Apps = () => {
  const [modalAppAdd, setModalAppAdd] = useState(false);
  const { user } = useUser();
  const [appData, setAppData] = useState<Partial<TApps.AppData> | null>(null);
  const [modalType, setModalType] = useState<'description' | 'edit' | 'delete' | ''>('');
  const [filters, setFilters] = useState({ page: 0, size: 20 });

  const methods = useForm({ mode: 'onBlur' });

  const { appsData, isAppsLoading, isAppsFetching } = useGetApps({
    page: filters.page,
    size: filters.size
  });

  useEffect(() => {
    if (!modalAppAdd) {
      setAppData(null);
    }
  }, [modalAppAdd]);
  const { download } = useActionApps();

  const onDownload = async (data: TApps.DownloadApp) => {
    try {
      const fileResponse = await download.mutateAsync(data);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([fileResponse.data], { type: 'application/octet-stream' })
      );
      a.download = `cashdash-apk-${data.version}.apk`;
      a.style.display = 'none';

      // Append anchor to body.
      document.body.appendChild(a);
      a.click();

      // Remove anchor from body
      document.body.removeChild(a);
      setModalAppAdd(false);
    } catch (error) {
      addMessageToast(error);
    }
  };

  const onActionsApk = (data: Partial<TApps.AppData> | null, action: string) => {
    switch (action) {
      case 'description':
        setModalType(action);
        setAppData(data);
        setModalAppAdd(true);
        break;
      case 'delete':
        setAppData(data);
        setModalType(action);
        setModalAppAdd(true);
        break;
      case 'edit':
        setAppData(data);
        setModalAppAdd(true);
        break;
      default:
        return null;
    }
  };

  const onClose = () => {
    setModalType('');
    setAppData(null);
    methods.reset();
    setModalAppAdd(false);
  };

  return (
    <>
      <HeaderWrapper>
        <Typography
          variant='h3'
          color='primary'
          sx={{ flexGrow: 1, fontSize: 28, lineHeight: '36px' }}>
          App version
        </Typography>

        <RoleAccessWrapper accessCondition={ROLES.APKs.canCreate} currentRole={user?.role}>
          <Button
            variant='contained'
            onClick={() => {
              setModalAppAdd(true);
            }}
            sx={{ p: '10px 28px 10px 24px', maxWidth: 162 }}
            startIcon={<Add />}>
            Add app
          </Button>
        </RoleAccessWrapper>
      </HeaderWrapper>

      <TableApps
        appsData={appsData}
        isAppsFetching={isAppsFetching}
        isAppsLoading={isAppsLoading}
        setFilter={setFilters}
        onActionsApk={onActionsApk}
        onDownload={onDownload}
      />

      <AddAppModal
        isOpen={modalAppAdd}
        setIsOpen={setModalAppAdd}
        modalType={modalType}
        onClose={onClose}
        appData={appData!}
        isApkLoading={download.isPending}
      />
    </>
  );
};
