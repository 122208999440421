import { GridColDef } from '@mui/x-data-grid';

import { TUsers } from 'shared/services/types/users.types';

const FULL_TABLE_WIDTH = 1790;

export const COLUMNS_USERS: GridColDef<TUsers.UserData>[] = [
  //TODO добавить удаление
  // {
  //   field: 'selected',
  //   headerName: '',
  // },
  {
    field: 'id',
    headerName: '№',
    width: 317 / FULL_TABLE_WIDTH
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 330 / FULL_TABLE_WIDTH
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 333 / FULL_TABLE_WIDTH
  },
  {
    field: 'method_id',
    headerName: 'Method',
    width: 346 / FULL_TABLE_WIDTH
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 247 / FULL_TABLE_WIDTH
  },
  {
    field: 'edit',
    headerName: '',
    width: 128 / FULL_TABLE_WIDTH
  }
];
