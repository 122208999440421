import React, { FC, useEffect, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, SxProps, TableCell, TableRow, Theme } from '@mui/material';

import { LogoIcon } from 'shared/assets/icons';
import { ROLES } from 'shared/constants';
import { useContainerHeight, useUser } from 'shared/hooks';
import { useUrlParams } from 'shared/hooks/useUrlParams';
import { TBanks } from 'shared/services/types/banks.types';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { RoleAccessWrapper } from 'shared/ui/role-access-wrapper';
import { TableLayout } from 'shared/ui/table-layout';
import { CustomTooltip } from 'shared/ui/tooltip';

import { COLUMNS_USERS } from '../mock-data/COL_USERS';

interface IProps {
  banksData?: TBanks.GetBanksSuccess;
  isBankFetching?: boolean;
  isBankLoading?: boolean;
  onEditBank: (data: TBanks.BankData) => void;
  methodsItems?: TMethods.MethodItem[];
  setFilter: React.Dispatch<
    React.SetStateAction<{
      page: number;
      size: number;
    }>
  >;
}
export const TableBanks: FC<IProps> = ({
  banksData,
  isBankLoading,
  onEditBank,
  methodsItems,
  setFilter
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);
  const { user } = useUser();

  const { paramsObj } = useUrlParams();
  const page = parseInt(paramsObj.page) - 1;

  useEffect(() => {
    setFilter({ size, page });
  }, [size, page]);

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip'
      }}>
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden'
        }}>
        <Box
          px={12}
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',
            height: height
          }}
          ref={ref}>
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              ...customTableStyles
            }}>
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              rows={banksData?.items || []}
              columns={COLUMNS_USERS}
              total={banksData?.total || 0}>
              {isBankLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {banksData?.items?.map((row, id) => {
                    const methodsName = row.methods?.map(item => item.name);
                    return (
                      <TableRow key={id}>
                        <TableCell component='th' scope='row'>
                          {id + 1 + banksData?.page * banksData?.size}
                        </TableCell>
                        <TableCell align='left'>
                          <Box display={'flex'} alignItems={'center'} gap={6}>
                            {!row.icon_file ? (
                              <LogoIcon height={28} width={28} />
                            ) : (
                              <Box
                                component={'img'}
                                width={28}
                                height={28}
                                src={row.icon_file}
                                alt='icon'
                                sx={{ flex: '0 0 28px', display: 'flex', objectFit: 'contain' }}
                              />
                            )}

                            {row.name}
                          </Box>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {methodsName?.join(', ')}
                        </TableCell>
                        <TableCell align='left'>{row.package}</TableCell>
                        <TableCell align='left'>{row.phone}</TableCell>
                        <TableCell align='left' sx={{ cursor: 'pointer' }}>
                          <RoleAccessWrapper
                            accessCondition={ROLES.Banks.canUpdate}
                            currentRole={user?.role}>
                            <Box width={'24px'} sx={{ marginLeft: 'auto' }}>
                              <CustomTooltip title={'Edit bank'}>
                                <Box
                                  display={'flex'}
                                  gap={8}
                                  justifyContent={'flex-end'}
                                  sx={{ color: '#AEAAAB' }}>
                                  <ModeEditOutlineOutlinedIcon
                                    onClick={() => {
                                      onEditBank(row);
                                    }}
                                    style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                                  />
                                </Box>
                              </CustomTooltip>
                            </Box>
                          </RoleAccessWrapper>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const customTableStyles = {
  '.MuiTableHead-root': {
    '.MuiTableCell-root': {
      '&:first-child': {
        paddingLeft: '16px'
      },
      '&:last-child': {
        paddingRight: '16px'
      }
    }
  },
  '.MuiTableBody-root': {
    '.MuiTableCell-root': {
      height: '20px',
      padding: '16px 0',
      borderBottom: '1px solid #BDC1D2',
      color: '#44464F',
      fontWeight: '400',
      '&:first-child': {
        paddingLeft: '16px'
      },
      '&:last-child': {
        paddingRight: '16px'
      }
    }
  }
} as SxProps<Theme>;
