import React, { FC, ReactElement, useEffect, useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';

import { secondsToTimeString } from 'widgets/statistics/utils';

import { ChartLine } from 'shared/ui/chart/char-line';
import { ChartBar } from 'shared/ui/chart/chart-bar';
import { TChart } from 'shared/ui/chart/chart.types';
import { CustomTooltip } from 'shared/ui/tooltip';
import { compareWithPrevious, formatNumber } from 'shared/utils';

interface IProps {
  name: string;
  description?: string;
  icon: React.ReactNode;
  activeLabel: string;
  headerExtra?: ReactElement;
  bodyExtra?: ReactElement;
  isLine?: boolean;
  isTime?: boolean;
  data?: TChart.DataLineChartOptions[];
  lines?: TChart.Lines[];
  isVertical?: boolean;
  hivePerView?: boolean;
  hiddenChart?: boolean;
}

export const StatChartWrapper: FC<IProps> = ({
  name,
  description,
  icon,
  data,
  activeLabel,
  headerExtra,
  bodyExtra,
  isLine,
  isTime,
  lines,
  isVertical,
  hivePerView,
  hiddenChart
}) => {
  const [activeData, setActiveData] = useState<number>(0);

  useEffect(() => {
    const defaultActiveData = (data?.length || 1) - 1;

    setActiveData(defaultActiveData);
  }, [data]);

  const result = compareWithPrevious(
    data?.map(v => v?.value?.toString() || '') || [],
    activeData,
    !!isLine
  );
  const dataValue = data?.[activeData]?.value;

  const value = isTime
    ? secondsToTimeString(dataValue ? +dataValue : 0, true)
    : `$${formatNumber(dataValue ? +dataValue : 0, 0)}`;

  const colorArrow = () => {
    if (!result || result === '0' || result === 'None') {
      return { background: '#E1E2EC', color: '#44464F' };
    }

    const isNegative = result.includes('-');
    const isTimeCondition = isTime && isNegative;

    if (isTimeCondition) {
      return { background: '#1DBF73', color: '#fff' };
    }

    if (isTime) {
      return { background: '#BA1A1A', color: '#fff' };
    }

    return { background: isNegative ? '#BA1A1A' : '#1DBF73', color: '#fff' };
  };
  return (
    <Box
      height={hiddenChart ? '121px' : '100%'}
      maxHeight={296}
      display={'flex'}
      justifyContent={hiddenChart ? 'space-between' : ''}
      flexDirection={'column'}
      sx={{ borderRadius: '16px', p: '12px' }}>
      <Box display={'flex'} justifyContent={'space-between'} p={'4px 0'}>
        <Box display={'flex'} gap={'6px'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            {icon}
          </Box>
          <Box display={'flex'} gap={'2px'} alignItems={'center'}>
            <Box fontWeight={600} fontSize={18}>
              {name}
            </Box>

            <Box>
              {description && (
                <CustomTooltip
                  placement='bottom-end'
                  title={
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'end'}
                      sx={{ span: { lineHeight: '20px' } }}>
                      {description}
                    </Box>
                  }
                  optionsOffset={() => {
                    return [10, 0];
                  }}>
                  <IconButton sx={{ padding: '2px', width: '24px', height: '24px' }}>
                    <InfoOutlinedIcon
                      height={'20px'}
                      sx={{
                        fill: '#DEDEDE'
                      }}
                    />
                  </IconButton>
                </CustomTooltip>
              )}
            </Box>
          </Box>
        </Box>
        {headerExtra}
      </Box>
      {!hivePerView && (
        <Box
          sx={{ mt: '11px' }}
          display={'flex'}
          gap={'5px'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Box
            sx={{
              fontSize: '30px',
              color: '#080808',
              lineHeight: '46px',
              fontWeight: 600,
              span: {
                fontSize: '20px',
                color: '#878787'
              }
            }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
          {result && (
            <Box display={'flex'} gap={'4px'} alignItems={'center'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                sx={{
                  p: '4px 6px',
                  lineHeight: '15.5px',
                  color: colorArrow().color,
                  background: colorArrow().background,
                  borderRadius: '12px',
                  fontSize: '14px'
                }}>
                {!['None', '0'].includes(result) && (
                  <ArrowUpwardIcon
                    sx={{
                      height: '16px',
                      width: '16px',
                      rotate: result?.includes('-') ? '180deg' : ''
                    }}
                  />
                )}
                {result}
                {!['None', '0'].includes(result) && `%`}
              </Box>
              <Box
                display={'flex'}
                fontSize={'14px'}
                color={'#878787'}
                alignItems={'center'}>{`vs last ${activeLabel?.toLocaleLowerCase()}`}</Box>
            </Box>
          )}
        </Box>
      )}
      {bodyExtra}

      {!hiddenChart && (
        <Box height={'100%'}>
          {isLine ? (
            <ChartLine
              data={data}
              isTime={isTime}
              lines={lines}
              activeData={activeData}
              onChangeActive={e => {
                setActiveData(e);
              }}
            />
          ) : (
            <ChartBar
              data={data}
              isVertical={isVertical}
              activeData={activeData}
              onChangeActive={e => {
                setActiveData(e);
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
