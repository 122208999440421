import { GridColDef } from '@mui/x-data-grid';

import { TAccounts } from 'shared/services/types/accounts.types';

const FULL_TABLE_WIDTH = 1790;

export const COLUMNS_ACCOUNTS: GridColDef<TAccounts.AccountItem>[] = [
  {
    field: 'id',
    headerName: '№',
    width: 467 / FULL_TABLE_WIDTH
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 494 / FULL_TABLE_WIDTH
  },
  {
    field: 'sub_id',
    headerName: 'Subaccount',
    width: 634 / FULL_TABLE_WIDTH
  },
  {
    field: 'edit',
    headerName: '',
    width: 96 / FULL_TABLE_WIDTH
  }
];
