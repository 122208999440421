import { FC, useEffect, useState } from 'react';

import { IStepProps } from '..';
import { Add } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, TextField } from '@mui/material';

import { useMessageActions } from 'features/add-method/api';
import { useGetMessages } from 'features/add-method/api/useGetMessages';

import { CustomButton } from 'shared/ui/custom-button';
import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils';

interface IProps extends IModalProps, IStepProps {
  method_id?: number;
  setText: (texts: string[]) => void;
}

export const SelectedTextModal: FC<IProps> = ({
  close,
  isOpen,
  onBack,
  onNext,
  method_id,
  setText
}) => {
  const { deleteMessage } = useMessageActions();
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  const [textList, setTextList] = useState<string[]>([]);
  const [textArea, setTextArea] = useState('');

  const [addTextModalOpen, setAddTextModalOpen] = useState(false);
  const { isMessagesFetching, isMessagesLoading, messages } = useGetMessages({
    page: 0,
    size: 30,
    method_id: method_id?.toString()
  });

  const onSelectedIndex = (id: number) => {
    const newIndex = [...activeIndex];
    if (newIndex.includes(id)) {
      newIndex.splice(newIndex.indexOf(id), 1);
    } else {
      newIndex.push(id);
    }
    setActiveIndex(newIndex);
  };

  const onNextStep = () => {
    setText(textList.filter((item, index) => activeIndex.includes(index)));
    onNext?.();
  };

  const onAddText = () => {
    setTextList(prevState => {
      const textsToAdd = [] as string[];
      textsToAdd.push(...prevState, textArea);
      const newTextIndex = textsToAdd.length - 1;
      setActiveIndex(activeIndexPrevState => [...activeIndexPrevState, newTextIndex]);
      return textsToAdd;
    });
    setAddTextModalOpen(false);
  };

  const deleteTextMessage = async (id: number) => {
    try {
      await deleteMessage.mutateAsync(id);
    } catch (error: any) {
      addMessageToast(error);
    }
  };

  const onDeleteMessage = async () => {
    const messageIds = messages?.items?.filter((v, index) => activeIndex.includes(index));
    const newMessageList = textList?.filter((v, index) => !activeIndex.includes(index));

    try {
      messageIds?.forEach(i => {
        deleteTextMessage(i.id);
      });
      setActiveIndex([]);
      setTextList(newMessageList);
    } catch (error) {}
  };

  useEffect(() => {
    //TODO пока что сбрасывает добавленные текста, при хождении между модалками
    if (!!method_id && messages?.items) {
      setTextList(messages!.items.map(i => i.text));
    }
  }, [messages]);

  return (
    <ModalTemplate
      close={close}
      isOpen={isOpen}
      isConfirmationNeeded
      titleText={addTextModalOpen ? 'Add SMS/push' : 'SMS/push'}
      onBack={() => (addTextModalOpen ? setAddTextModalOpen(false) : onBack?.())}>
      <Box p={'30px 24px'} m={'0 auto'}>
        <Box
          display={'flex'}
          sx={{ flexDirection: 'column', position: 'relative' }}
          gap={'16px'}
          maxHeight={'500px'}
          overflow={'auto'}>
          {addTextModalOpen ? (
            <TextField
              multiline
              placeholder='Enter SMS/push text'
              label='SMS/push*'
              rows={6.625}
              sx={{
                marginTop: '6px'
              }}
              onChange={data => {
                setTextArea(data.target.value);
              }}
            />
          ) : !isMessagesFetching && !isMessagesLoading ? (
            <>
              <Button
                type='button'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 0,
                  fontSize: '16px',
                  lineHeight: '20px'
                }}
                endIcon={<Add />}
                onClick={() => {
                  setAddTextModalOpen(true);
                }}>
                Add text
              </Button>
              {textList.map((item, i) => (
                <Box
                  key={item}
                  onClick={() => onSelectedIndex(+i)}
                  sx={{ cursor: 'pointer' }}
                  display={'flex'}
                  gap={'14px'}
                  p={'12px 16px'}
                  alignItems={'flex-start'}
                  borderRadius={'4px'}
                  border={'1px solid #BDC1D2'}>
                  <Checkbox sx={{ padding: '0' }} checked={activeIndex.includes(i)} />

                  <Box sx={{ textAlign: 'left', direction: 'ltr', unicodeBidi: 'bidi-override' }}>
                    {item}
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box
              sx={{
                minWidth: 1,
                minHeight: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box display={'flex'} gap='10px'>
          {!!activeIndex?.length && !addTextModalOpen && (
            <CustomButton
              mode='delete'
              disabled={addTextModalOpen ? false : !activeIndex?.length}
              onClick={onDeleteMessage}>
              Delete
            </CustomButton>
          )}
          <CustomButton
            disabled={addTextModalOpen ? false : !activeIndex?.length}
            onClick={addTextModalOpen ? onAddText : onNextStep}>
            {addTextModalOpen ? 'Add' : 'Choose'}
          </CustomButton>
        </Box>
      </Box>
    </ModalTemplate>
  );
};
