import { useLayoutEffect } from 'react';

import { User } from 'widgets/users';

import { ROLES } from 'shared/constants';
import { useUser } from 'shared/hooks';
import { useCustomNavigate } from 'shared/hooks/useCustomNavigate';
import { Layout } from 'shared/ui/layout';

const UsersPage = () => {
  const { rolesAccess } = useUser();
  const isAccess = rolesAccess(ROLES.User.canRead);
  const { customNavigate } = useCustomNavigate();

  useLayoutEffect(() => {
    if (!isAccess) {
      customNavigate('/');
    }
  }, [isAccess]);

  return (
    <Layout bankName='Users' isSingleMethod>
      <User />
    </Layout>
  );
};

export default UsersPage;
