import { useSearchParams } from 'react-router-dom';

interface QueryParams {
  [key: string]: string;
}

export const useUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = () => {
    const result: QueryParams = {};

    //@ts-ignore
    for (const [key, value] of searchParams.entries()) {
      result[key] = value;
    }

    return result;
  };

  const setParam = (name: string, value: string) => {
    setSearchParams(prev => {
      prev.set(name, value);
      return prev;
    });
  };

  const resetParam = (name: string) => {
    if (searchParams.has(name)) {
      const token = searchParams.get(name);
      if (token) {
        searchParams.delete(name);
        setSearchParams(searchParams);
      }
    }
  };

  return { paramsObj: getParams(), setParam, resetParam };
};
