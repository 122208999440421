import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AppsService } from 'shared/services/apps.service';
import { TApps } from 'shared/services/types/apps.types';

export const useActionApps = () => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (data: TApps.CreateApp) => AppsService.createApp(data),
    mutationKey: ['create app'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get apps'] });
    }
  });

  const update = useMutation({
    mutationFn: (data: TApps.UpdateApp) => AppsService.updateApp(data),
    mutationKey: ['update app'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get apps'] });
    }
  });

  const remove = useMutation({
    mutationFn: (data: TApps.DeleteApp) => AppsService.deleteApp(data),
    mutationKey: ['delete app'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get apps'] });
    }
  });

  const download = useMutation({
    mutationFn: (data: TApps.DownloadApp) => AppsService.downloadApp(data),
    mutationKey: ['download app'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get apps'] });
    }
  });

  return { create, update, remove, download };
};
