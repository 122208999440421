import React, { FC, useMemo, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { theme } from 'app/provider/material-ui/theme';

import { useGetCTV } from 'features/statistics/api';

import { StatChartWrapper } from 'entities/stat-chart-wrapper';

import { CTVIcon } from 'shared/assets/icons';
import { TStatistics } from 'shared/services/types/statistics.types';
import { TChart } from 'shared/ui/chart/chart.types';
import { formatNumber, getFormattedDate } from 'shared/utils';

import { getMonthName, weekNumberToDateRange } from '../utils';

interface IProps extends TChart.ChartProps {}

const dataLabel = [
  {
    value: 'day',
    label: 'Day'
  },
  {
    value: 'week',
    label: 'Week'
  },
  {
    value: 'month',
    label: 'Month'
  }
] as const;

export const StatsCTV: FC<IProps> = ({ methodId, hiddenChart, filterMethod }) => {
  const [activePeriod, setActivePeriod] = useState(dataLabel.at(-1));
  const { data, isPending } = useGetCTV({
    ...filterMethod,
    period: activePeriod?.value,
    method_id: [methodId]
  });

  const dataCVT = useMemo(() => {
    if (activePeriod?.label === 'Month') {
      const res = data?.items.map(v => ({ name: getMonthName(+v.date), value: +v.total_income }));
      return res;
    }
    if (activePeriod?.label === 'Week') {
      const res = data?.items.map(v => ({
        name: weekNumberToDateRange(+v.date),
        value: +v.total_income
      }));
      return res;
    }
    if (activePeriod?.label === 'Day') {
      const res = data?.items.map(v => ({
        name: getFormattedDate(v.date, 'MM.dd'),
        value: +v.total_income
      }));
      return res;
    }
  }, [data]);

  const onChangePeriod = (data: any) => {
    setActivePeriod(data);
  };

  return (
    <Box
      height={'100%'}
      width={'100%'}
      sx={{ minWidth: '427px' }}
      position={'relative'}
      borderRadius={'16px'}
      border={'1px solid #BDC1D2'}>
      <StatChartWrapper
        name='CTV'
        activeLabel={activePeriod!.label!}
        description='Confirmed Transaction Volume'
        data={dataCVT}
        hiddenChart={hiddenChart}
        icon={<CTVIcon />}
        headerExtra={
          <Box display={'flex'} gap={'4px'}>
            {dataLabel.map(v => {
              const active = v.label === activePeriod?.label;

              return (
                <Box
                  onClick={() => onChangePeriod(v)}
                  key={v.value}
                  display={'flex'}
                  alignItems={'center'}
                  fontSize={'13px'}
                  fontWeight={600}
                  sx={{
                    p: '4px 16px',
                    lineHeight: '15.5px',
                    border: active ? '1px solid primary.main' : '1px solid #949497',
                    color: active ? '#fff' : '#949497',
                    background: active ? theme.palette.primary.main : '#94949',
                    borderRadius: '12px',
                    cursor: active ? 'default' : 'pointer'
                  }}>
                  {v.label}
                </Box>
              );
            })}
          </Box>
        }
      />
    </Box>
  );
};
