import { FC } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { hasFormValuesChanged } from 'shared/utils';

interface IProps {
  isOpen: boolean;
  onSubmit: (data: any) => Promise<void>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MatchTransaction: FC<IProps> = ({ setIsOpen, isOpen, onSubmit }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), []);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      titleText={'Match transaction'}
      reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item>
              <Input
                name='transaction_id'
                label='Enter transaction ID*'
                placeholder='Enter transaction ID'
                rules={{ required: 'Please, enter transaction ID' }}
                methods={methods}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 15, borderRadius: 50, textTransform: 'none', height: 40 }}
          onSubmit={onSubmit}>
          Match
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
