import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { Controller, FieldValues, UseFormReset, UseFormReturn } from 'react-hook-form';

import { useGetAccounts } from 'features/users/api';

import { TAccounts } from 'shared/services/types/accounts.types';
import { TFilters } from 'shared/services/types/common.types';
import { FilterItemDate } from 'shared/ui/filter-item-date';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { SelectSearch } from 'shared/ui/select-multi';
import { hasFormValuesChanged } from 'shared/utils';

interface IProps extends PropsWithChildren {
  methodID: number;
  onSubmit: (data: TFilters) => void;
  onClose: (isReset?: boolean) => void;
  isFilterOpen: boolean;
  setIsFilterOpen: any;
  methods: UseFormReturn<FieldValues, any, undefined>;
  reset: UseFormReset<FieldValues>;
  userData: any;
}

export const FilterModal: FC<IProps> = ({
  methodID,
  onSubmit,
  onClose,
  isFilterOpen,
  setIsFilterOpen,
  methods,
  reset,
  userData
}) => {
  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), userData ?? []);

  const defaultFilterAccs = {
    size: 1000,
    page: 0,
    order_by: 'method_id',
    method_id: methodID,
    is_desc_sort: true
  } as TAccounts.GetAccount;

  const { isAccountsLoading, accounts } = useGetAccounts(defaultFilterAccs);

  const accSorted = useMemo(
    () =>
      accounts?.items.map(account => {
        return { value: account.account_id, label: account.account_id };
      }),
    [accounts]
  );

  useEffect(() => {
    if (isFilterOpen && userData) {
      methods.reset(userData);
    }
  }, [isFilterOpen, userData]);

  useEffect(() => {
    methods.reset();
  }, [methodID]);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={onClose}
      isOpen={isFilterOpen}
      titleText={'Filter'}
      reset={reset}
      onBack={() => setIsFilterOpen(false)}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item sx={{ minWidth: '19%' }}>
              <Input
                name='subagent_id'
                label='Sub ID'
                placeholder='Subagent ID'
                methods={methods}
              />
            </Grid>
            <Grid item sx={{ minWidth: '19%' }}>
              <SelectSearch
                isMulti
                inputLabel='Account'
                inputPlaceholder='Choose account'
                methods={methods}
                name='accountNumber'
                options={accSorted ?? []}
                isOptionsLoading={isAccountsLoading}
              />
            </Grid>
            <Grid item sx={{ minWidth: '19%' }}>
              <SelectSearch
                inputLabel='Status'
                inputPlaceholder='All'
                methods={methods}
                name='status'
                options={[
                  { value: 'received', label: 'Pending' },
                  { value: 'declined', label: 'Declined' },
                  { value: 'done', label: 'Approved' }
                ]}
              />
            </Grid>
            <Grid item sx={{ minWidth: '19%' }}>
              <Controller
                control={methods.control}
                name='dateRange'
                render={({ field: { onChange, onBlur, value } }) => (
                  <FilterItemDate
                    value={value === undefined ? [null, null] : value}
                    setValue={onChange}
                    labelText='Date from/to'
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'30px'}
          display={'flex'}
          gap={'16px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Button
            variant='contained'
            fullWidth
            onClick={() => onClose()}
            sx={{
              maxWidth: 175,
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              background: '#E3E3E4',
              color: '#1D1B20',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#e2e2e2'
              },
              height: 40
            }}>
            Cancel
          </Button>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              maxWidth: 175,
              height: 40,
              boxShadow: 'none'
            }}>
            Save
          </Button>
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
