import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

const useRefetchAccounts = () => {
  const queryClient = useQueryClient();

  const { method_id } = useParams();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['get account']
    });
  }, [method_id]);
};

export default useRefetchAccounts;
