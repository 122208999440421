import qs from 'qs';

import { axiosInstance } from 'shared/api';
import { getStatisticsUrl } from 'shared/api/config/api.config';

import { TStatistics } from './types/statistics.types';

export const StatisticsService = {
  async getStatCTV(data?: TStatistics.RequestCTV) {
    const response = await axiosInstance.get<TStatistics.Response>(getStatisticsUrl('/ctv'), {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: data
    });

    return response;
  },
  async getStatARTT(data?: TStatistics.RequestART) {
    const response = await axiosInstance.get<TStatistics.ResponseARTT>(getStatisticsUrl('/artt'), {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: data
    });

    return response;
  },
  async getStatATV(data?: TStatistics.RequestART) {
    const response = await axiosInstance.get<TStatistics.ResponseATV>(getStatisticsUrl('/atv'), {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: data
    });

    return response;
  },
  async getStatGeneral(data?: TStatistics.RequestGeneral) {
    const response = await axiosInstance.get<TStatistics.ResponseGeneral>(
      getStatisticsUrl('/general'),
      {
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        params: data
      }
    );

    return response;
  }
};
