import { PropsWithChildren } from 'react';

import { TRoles } from 'shared/services/types/common.types';

interface IProps extends PropsWithChildren {
  accessCondition: TRoles[] | TRoles;
  currentRole?: TRoles;
}

export const RoleAccessWrapper = ({ accessCondition, currentRole, children }: IProps) => {
  if (!currentRole || !accessCondition) return null;
  return accessCondition.includes(currentRole) ? <>{children}</> : null;
};
