import { FC } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Checkbox, CircularProgress, MenuItem, TextField } from '@mui/material';
import { Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { LogoIcon } from 'shared/assets/icons';
import { TSelectOption } from 'shared/services/types/common.types';

import { TooltipInput } from '../input/slices';

import { CustomListbox } from './components';

export type THeaderProps = {
  onClick: () => void;
  label: string;
};
interface IProps {
  isOptionsLoading?: boolean;
  options?: TSelectOption[];
  name: string;
  inputLabel: string;
  maxHeight?: number;
  inputPlaceholder: string;
  methods: UseFormReturn<FieldValues, any, undefined>;
  headerProps?: THeaderProps;
  key?: React.Key | null | undefined;
  isMulti?: boolean;
  isFooter?: boolean;
  disabled?: boolean;
  onChangeSelect?: (text: string | string[]) => void;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

export const SelectSearch: FC<IProps> = ({
  options,
  isOptionsLoading,
  name,
  inputLabel,
  inputPlaceholder,
  methods,
  headerProps,
  isMulti,
  isFooter,
  disabled,
  maxHeight = 350,
  rules,
  onChangeSelect
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const fieldError = methods?.formState.errors[name]?.message?.toString();

  const renderTagsLabel = (option: TSelectOption | string) => {
    if (typeof option === 'string') return option;
    //@ts-ignore
    if (typeof option === 'number') return options?.find(i => i.value === option).label.toString();
    return option.label;
  };

  const isRequired = !inputLabel?.includes('*') && rules?.required ? '*' : '';

  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={methods.control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          const currValue = options?.find(item => {
            if (Array.isArray(value)) value;

            return [value, value?.value].includes(item?.value);
          });

          const valueIsEmpty = Array.isArray(value) ? !value.length : !value;

          return (
            <Autocomplete
              multiple={isMulti}
              disabled={disabled}
              value={isMulti ? value : currValue}
              onBlur={onBlur}
              isOptionEqualToValue={(option, value) => {
                if (['string', 'number'].includes(typeof value)) return option?.value === value;
                return option?.value === value?.value;
              }}
              onChange={(event, newValue) => {
                if (Array.isArray(newValue)) {
                  const arrayValue = newValue?.map(item => {
                    return ['string', 'number'].includes(typeof item) ? item : item.value;
                  });
                  onChange(arrayValue);
                  onChangeSelect?.(arrayValue);
                } else {
                  const value = newValue?.value || null;

                  onChange(value);
                  onChangeSelect?.(value);
                }
              }}
              // TODO переделать
              // onInputChange={(e, inputValue) => {
              //   if (e?.target && e.type !== 'blur') {
              //     console.log(inputValue);
              //   }
              // }}
              options={options ?? []}
              disableCloseOnSelect={isMulti}
              limitTags={isMulti ? 1 : undefined}
              getOptionLabel={option => {
                return option.label ?? '';
              }}
              renderTags={(value: readonly TSelectOption[] | string[], getTagProps) =>
                value.map((option: TSelectOption | string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <>
                      <span>{`${renderTagsLabel(option)}`}</span>
                      {value.length !== 1 && index !== value.length - 1 && <span>,&nbsp;</span>}
                    </>
                  );
                })
              }
              renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                  <MenuItem {...optionProps}>
                    {isMulti && (
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                    {option.extra &&
                      (option.extra?.icon ? (
                        <Box
                          component={'img'}
                          width={20}
                          height={20}
                          src={option.extra?.icon}
                          alt='icon'
                          sx={{
                            flex: '0 0 20px',
                            display: 'flex',
                            objectFit: 'contain',
                            marginRight: 4
                          }}
                        />
                      ) : (
                        <LogoIcon
                          height={20}
                          width={20}
                          style={{
                            flex: '0 0 20px',
                            display: 'flex',
                            objectFit: 'contain',
                            marginRight: 8
                          }}
                        />
                      ))}
                    {option.label}
                  </MenuItem>
                );
              }}
              ListboxProps={{
                //@ts-ignore
                headerProps,
                isFooter
              }}
              ListboxComponent={CustomListbox}
              renderInput={params => {
                const paramStartAdd = params?.InputProps?.startAdornment;
                const isMultiTooltip = Array.isArray(paramStartAdd) &&
                  typeof paramStartAdd?.[1]?.props?.children === 'string' && (
                    <>
                      {paramStartAdd?.[0]}
                      <Box
                        sx={{
                          '.MuiBox-root': {
                            boxSizing: 'border-box',
                            maxHeight: '24px'
                          }
                        }}>
                        <TooltipInput
                          count={value?.length}
                          list={value?.map((i: string | number) =>
                            options?.find(opt => opt.value === i)
                          )}
                          onDelete={e => {
                            onChange(value?.filter((i: string | number) => i !== e));
                          }}
                        />
                      </Box>
                    </>
                  );
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: isMultiTooltip ? isMultiTooltip : paramStartAdd
                    }}
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    label={inputLabel + isRequired}
                    placeholder={isMulti && !valueIsEmpty ? '' : inputPlaceholder}
                    InputLabelProps={{
                      style: { fontSize: 12 },
                      shrink: true
                    }}
                    sx={{
                      '.MuiFormLabel-root': { color: fieldError && '#b20000' },
                      '.MuiAutocomplete-endAdornment': {
                        marginRight: '7px',
                        '.MuiAutocomplete-clearIndicator': {
                          visibility:
                            methods?.watch(name) && !isOptionsLoading ? 'visible' : 'hidden'
                        },
                        '.MuiAutocomplete-popupIndicator': {
                          display: methods?.watch(name) ? 'none' : 'inline-flex'
                        }
                      },
                      fieldset: {
                        border: fieldError && '1px solid #b20000 '
                      }
                    }}
                    variant='outlined'
                    fullWidth
                  />
                );
              }}
            />
          );
        }}
      />
      <Box sx={{ position: 'absolute', right: 44, top: 16, display: 'flex', alignItems: 'center' }}>
        {isOptionsLoading && methods?.watch(name) && (
          <CircularProgress
            sx={{
              width: '24px !important',
              height: '24px !important',
              '.MuiCircularProgress-svg': { width: '24px', height: '24px' }
            }}
          />
        )}
      </Box>
      <Box
        style={{
          marginLeft: '16px',
          fontSize: 12,
          color: '#b20000',
          textAlign: 'start',
          fontWeight: 'normal'
        }}>
        {(fieldError && <span>{fieldError}</span>) || fieldError}
      </Box>
    </Box>
  );
};
